import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  IconPlus,
  IconMinusRounded,
  IconPresentation,
  IconAddItem,
  IconBarChart,
} from '@edulastic/icons'
import {
  EduButton,
  EduElse,
  EduIf,
  EduThen,
  SpinLoader,
} from '@edulastic/common'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { segmentApi } from '@edulastic/api'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { themeColor } from '@edulastic/colors'
import { sanitizeUrl } from '@edulastic/common/src/utils/html'
import {
  TableViewStyled,
  StyledText,
  StyledTable,
  TestThumbnail,
  StyledPlusIconContainer,
  ActionSectionContainer,
  StatsContainer,
  TestThumbnailEmpty,
  ActionButton,
  StyledStatusWrap,
  SeeAllRow,
} from './styled'
import {
  getAssignmentsCountSelector,
  getDashboardAssignmentsDataSelector,
  getDashboardAssignmentsLoadingSelector,
} from '../../../ducks'
import {
  canEditTest,
  getEditTestNavigation,
} from '../../../../Assignments/utils'
import AssignmentActions from './AssignmentActions'
import {
  receiveAssignmentByIdAction,
  toggleReleaseScoreSettingsAction,
} from '../../../../src/actions/assignments'
import { getToggleReleaseGradeStateSelector } from '../../../../src/selectors/assignments'
import ReleaseScoreSettingsModal from '../../../../Assignments/components/ReleaseScoreSettingsModal/ReleaseScoreSettingsModal'
import { releaseScoreAction } from '../../../../src/actions/classBoard'
import { setIsTestPreviewVisibleAction } from '../../../../../assessment/actions/test'
import { TestPageProvider } from '../../../../TestPage/TestPageContext'
import TestPreviewModal from '../../../../Assignments/components/Container/TestPreviewModal'
import { getIsPreviewModalVisibleSelector } from '../../../../../assessment/selectors/test'
import PrintTestModal from '../../../../src/components/common/PrintTestModal'
import {
  getCurrentTerm,
  getUserId,
  getUserOrgId,
  getUserRole,
  isPremiumUserSelector,
} from '../../../../src/selectors/user'
import TestLinkModal from '../../../../Assignments/components/TestLinkModal/TestLinkModal'
import FeaturesSwitch from '../../../../../features/components/FeaturesSwitch'
import WithDisableMessage from '../../../../src/components/common/ToggleDisable'
import NoAssignmentData from './NoAssignmentData'
import { getAssignmentStatus } from '../../../constants'
import { AssignmentStatus } from '../../../../Assignments/components/LeftFilter'
import { getReportPathForAnalyze } from '../../../../Assignments/components/AnalyzeLink/AnalyzeLink'
import { StatusLabel } from '../../../../Assignments/components/TableList/styled'
import { setFilterInSession } from '../../../../../common/utils/helpers'

const TableView = ({
  userId,
  districtId,
  assignmentData,
  loading,
  loadAssignmentById,
  isShowReleaseSettingsPopup,
  toggleReleaseGradePopUp,
  setReleaseScore,
  setIsTestPreviewVisible,
  isPreviewModalVisible,
  error,
  history,
  userRole,
  t,
  termId,
  filterState,
  isPremiumUser,
  totalAssignmentsCount,
}) => {
  const [expandedRows, setExpandedRows] = useState([])
  const [currentTestId, setCurrentTestId] = useState('')
  const [currentAssignmentId, setCurrentAssignmentId] = useState()
  const [currentAssignmentClass, setCurrentAssignmentClass] = useState()
  const [openPrintModal, setOpenPrintModal] = useState(false)
  const [currentClassId, setCurrentClassId] = useState()
  const [showTestLinkModal, setShowTestLinkModal] = useState(false)

  const onOpenReleaseScoreSettings = (testId, assignmentId) => {
    loadAssignmentById({ testId, assignmentId })
    setCurrentTestId(testId)
  }

  const onUpdateReleaseScoreSettings = (releaseScore) => {
    const actionFilter = {
      grades: filterState.grades,
      subject: '',
      testTypes: [],
      folderId: '',
      classId: filterState.classIds,
      testId: '',
      assignedBy: '',
      termId,
      status: filterState.statuses,
      tags: [],
    }
    setReleaseScore(
      undefined,
      undefined,
      releaseScore,
      currentTestId,
      actionFilter
    )
    toggleReleaseGradePopUp(false)
  }

  const hidePreviewModal = () => {
    setIsTestPreviewVisible(false)
  }

  const showPreviewModal = (testId, assignmentId, assignmentClass) => {
    setIsTestPreviewVisible(true)
    setCurrentTestId(testId)
    setCurrentAssignmentId(assignmentId)
    setCurrentAssignmentClass(assignmentClass)
  }

  const onEnableEdit = (_currentTestId, testType) => {
    history.push(getEditTestNavigation(_currentTestId, testType))
  }

  const togglePrintModal = (
    _currentTestId = '',
    _currentAssignmentId,
    _currentClassId
  ) => {
    setOpenPrintModal(!openPrintModal)
    setCurrentTestId(_currentTestId)
    setCurrentAssignmentId(_currentAssignmentId)
    setCurrentClassId(_currentClassId)
  }

  const gotoPrintView = (data) => {
    segmentApi.genericEventTrack('TestPrint', {
      origin: 'dashboard',
      trigger: 'actions',
      role: userRole,
    })
    const { type, customValue, showAnswers, preferredLanguage } = data

    window.open(
      `/author/printAssessment/${currentTestId}?type=${type}&showAnswers=${showAnswers}&assignmentId=${currentAssignmentId}${
        currentClassId ? `&groupId=${currentClassId}` : ''
      }${
        type === 'custom' ? `&qs=${customValue}` : ''
      }&preferredLanguage=${preferredLanguage}`,
      '_blank'
    )
    togglePrintModal()
  }

  const showEmbedLinkModal = (testId) => {
    setShowTestLinkModal(true)
    setCurrentTestId(testId)
  }

  const expandedRowRender = (parentData) => {
    const expandedColumns = [
      {
        key: 'plus-icon-space-adjust',
        align: 'left',
        width: '2%',
        render: () => <StyledPlusIconContainer />,
      },
      {
        dataIndex: 'thumbnail-space-adjust',
        key: 'thumbnail',
        align: 'left',
        width: '3%',
        render: () => <TestThumbnailEmpty />,
      },
      {
        dataIndex: 'className',
        key: 'className',
        width: '35%',
        render: (className) => (
          <StyledText data-cy="class" color="#000" ellipsis>
            {className}
          </StyledText>
        ),
      },
      {
        dataIndex: 'status',
        key: 'status',
        width: '25%',
        align: 'center',
        render: (status, row) => (
          <StyledStatusWrap data-cy="status">
            <StatusLabel
              status={status}
              fontSize="11px"
              style={{ fontWeight: '600' }}
            >
              {getAssignmentStatus(status, row.isPaused)}
            </StatusLabel>
          </StyledStatusWrap>
        ),
      },
      {
        dataIndex: 'stats',
        key: 'submittedStudents',
        width: '10%',
        align: 'center',
        render: (stats) => (
          <StatsContainer>
            <StyledText data-cy="submitted">
              {stats.gradedNumber + stats.inGradingNumber}/{stats.assignedCount}
              {' Submitted'}
            </StyledText>
          </StatsContainer>
        ),
      },
      {
        dataIndex: 'stats',
        key: 'gradedStudents',
        width: '10%',
        align: 'center',
        render: (stats) => (
          <StatsContainer width="75px">
            <StyledText data-cy="graded">
              {stats.gradedNumber} Graded
            </StyledText>
          </StatsContainer>
        ),
      },
      {
        key: 'actions',
        align: 'right',
        width: '15%',
        render: (row) => (
          <ActionSectionContainer>
            <Tooltip placement="bottom" title="Live Class Board">
              <Link
                data-cy="lcb"
                to={`/author/classboard/${row.assignmentId}/${row._id}`}
                onClick={(e) => {
                  e.stopPropagation()
                  segmentApi.genericEventTrack('Dashboard:Assignment:LCB')
                }}
              >
                <ActionButton btnType="secondary" isGhost IconBtn noHover>
                  <IconPresentation alt="Images" className="actionIcon" />
                </ActionButton>
              </Link>
            </Tooltip>
            {row.status !== AssignmentStatus.DONE && (
              <FeaturesSwitch
                inputFeatures="expressGrader"
                actionOnInaccessible="hidden"
                groupId={row._id}
              >
                <WithDisableMessage
                  disabled={row.hasRandomQuestions}
                  errMessage={t('common.randomItemsDisableMessage')}
                >
                  <Tooltip placement="bottom" title="Express Grader">
                    <Link
                      data-cy="eg"
                      to={`/author/expressgrader/${row.assignmentId}/${row._id}`}
                      disabled={row.hasRandomQuestions}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ActionButton btnType="secondary" isGhost IconBtn noHover>
                        <IconAddItem alt="Images" className="actionIcon" />
                      </ActionButton>
                    </Link>
                  </Tooltip>
                </WithDisableMessage>
              </FeaturesSwitch>
            )}
            {row.status === AssignmentStatus.DONE && isPremiumUser && (
              <Tooltip
                placement="bottom"
                title="Performance by Students Report"
              >
                <Link
                  data-cy="performanceByStudents"
                  to={getReportPathForAnalyze(
                    '/author/reports/performance-by-students/test/',
                    {
                      testId: parentData._id,
                      termId,
                      testType: parentData.testType,
                      classId: row._id,
                    }
                  )}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation()
                    segmentApi.genericEventTrack('Dashboard:Assignment:SAR-PS')
                  }}
                >
                  <ActionButton btnType="secondary" isGhost IconBtn noHover>
                    <IconBarChart alt="Images" className="actionIcon" />
                  </ActionButton>
                </Link>
              </Tooltip>
            )}
          </ActionSectionContainer>
        ),
      },
    ]
    return (
      <StyledTable
        pagination={false}
        dataSource={parentData.classes}
        columns={expandedColumns}
        data-cy={parentData._id}
        style={{ cursor: 'pointer' }}
        onRow={(record) => ({
          onClick: () => {
            history.push(
              `/author/classboard/${record.assignmentId}/${record._id}`
            )
          },
        })}
      />
    )
  }
  const columns = [
    {
      key: 'plus-icon',
      align: 'left',
      width: '2%',
      render: (row) => (
        <StyledPlusIconContainer>
          {expandedRows.includes(row.key) ? (
            <IconMinusRounded
              data-cy="collapseRow"
              style={{ fill: themeColor }}
            />
          ) : (
            <IconPlus data-cy="expandRow" style={{ fill: themeColor }} />
          )}
        </StyledPlusIconContainer>
      ),
    },
    {
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      align: 'left',
      width: '3%',
      render: (imgSrc) => (
        <TestThumbnail
          src={sanitizeUrl(imgSrc)}
          alt="Test thumbnail"
          width="42px"
        />
      ),
    },
    {
      dataIndex: 'testTitle',
      key: 'testTitle',
      width: '35%',
      render: (title) => (
        <StyledText data-cy="assignmentName" ellipsis color="#000">
          {title}
        </StyledText>
      ),
    },
    {
      dataIndex: 'classes',
      key: 'classes',
      width: '25%',
      align: 'center',
      render: (classes) => (
        <StyledText data-cy="ButtonToShowAllClasses">
          {classes.length} Class{classes.length > 1 ? 'es' : ''}
        </StyledText>
      ),
    },
    {
      dataIndex: 'stats',
      key: 'submittedStudents',
      width: '10%',
      align: 'center',
      render: (stats) => (
        <StatsContainer>
          <StyledText data-cy="testSubmitted">
            {stats.submitted}/{stats.total} Submitted
          </StyledText>
        </StatsContainer>
      ),
    },
    {
      dataIndex: 'stats',
      key: 'gradedStudents',
      width: '10%',
      align: 'center',
      render: (stats) => (
        <StatsContainer width="75px">
          <StyledText data-cy="testGraded">{stats.graded} Graded</StyledText>
        </StatsContainer>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      width: '15%',
      render: (record) => (
        <ActionSectionContainer>
          <AssignmentActions
            rowData={record}
            onOpenReleaseScoreSettings={onOpenReleaseScoreSettings}
            showPreviewModal={showPreviewModal}
            toggleEditModal={onEnableEdit}
            togglePrintModal={togglePrintModal}
            showEmbedLinkModal={showEmbedLinkModal}
            showViewSummary={
              !!record.classes.some(
                ({ stats }) => stats.gradedNumber || stats.inGradingNumber
              )
            }
            canEdit={canEditTest(record, userId)}
          />
        </ActionSectionContainer>
      ),
    },
  ]
  const handleExpandedRowsChange = (testId) => {
    setExpandedRows((state) => {
      if (state.includes(testId)) {
        return state.filter((item) => item !== testId)
      }
      return [...state, testId]
    })
  }

  const handleAssignmentNavigation = () => {
    history.push(`/author/assignments`)
    setFilterInSession({
      key: 'assignments_filter',
      userId,
      districtId,
      filter: {
        grades: filterState.grades ? filterState.grades.split(',') : [],
        classId: filterState.classIds,
        status: filterState.statuses,
      },
    })
    segmentApi.genericEventTrack('Dashboard:Assignment:SeeAll', {
      source: 'Dashboard',
    })
  }

  if (!loading && !assignmentData.length) {
    return <NoAssignmentData />
  }
  return (
    <>
      <TableViewStyled>
        <EduIf condition={loading}>
          <EduThen>
            <SpinContainer>
              <SpinLoader />
            </SpinContainer>
          </EduThen>
          <EduElse>
            <StyledTable
              pagination={false}
              dataSource={assignmentData}
              columns={columns}
              expandedRowRender={expandedRowRender}
              expandIconAsCell={false}
              expandIconColumnIndex={-1}
              onRow={(record) => ({
                onClick: () => handleExpandedRowsChange(record.key),
              })}
              expandedRowKeys={expandedRows}
            />
            {/* 8 is the page size provided as default in the backend api */}
            {totalAssignmentsCount > 8 && (
              <SeeAllRow>
                <EduButton
                  onClick={handleAssignmentNavigation}
                  fontSize="12px"
                  isGhost
                  data-cy="seeAllAssignmentsAtBottom"
                >
                  See All Assignments
                </EduButton>
              </SeeAllRow>
            )}
          </EduElse>
        </EduIf>
      </TableViewStyled>
      <ReleaseScoreSettingsModal
        subText=" Selected score policy will be applied to all the classes within
      assignment"
        showReleaseGradeSettings={isShowReleaseSettingsPopup}
        onCloseReleaseScoreSettings={() => toggleReleaseGradePopUp(false)}
        updateReleaseScoreSettings={onUpdateReleaseScoreSettings}
      />
      <TestPageProvider>
        <TestPreviewModal
          isModalVisible={isPreviewModalVisible}
          testId={currentTestId}
          error={error}
          showStudentPerformance
          closeTestPreviewModal={hidePreviewModal}
          currentAssignmentId={currentAssignmentId}
          currentAssignmentClass={currentAssignmentClass}
        />
      </TestPageProvider>
      {openPrintModal && (
        <PrintTestModal
          onProceed={gotoPrintView}
          onCancel={togglePrintModal}
          currentTestId={currentTestId}
          assignmentId={currentAssignmentId}
          showAnswerCheckbox
        />
      )}
      <TestLinkModal
        isVisible={showTestLinkModal}
        toggleModal={() => setShowTestLinkModal(!showTestLinkModal)}
        testId={currentTestId}
      />
    </>
  )
}

const enhance = compose(
  withRouter,
  withNamespaces('assignmentCard'),
  connect(
    (state) => ({
      userId: getUserId(state),
      districtId: getUserOrgId(state),
      assignmentData: getDashboardAssignmentsDataSelector(state),
      loading: getDashboardAssignmentsLoadingSelector(state),
      isShowReleaseSettingsPopup: getToggleReleaseGradeStateSelector(state),
      isPreviewModalVisible: getIsPreviewModalVisibleSelector(state),
      error: state?.test?.error || false,
      userRole: getUserRole(state),
      termId: getCurrentTerm(state),
      isPremiumUser: isPremiumUserSelector(state),
      totalAssignmentsCount: getAssignmentsCountSelector(state),
    }),
    {
      loadAssignmentById: receiveAssignmentByIdAction,
      toggleReleaseGradePopUp: toggleReleaseScoreSettingsAction,
      setReleaseScore: releaseScoreAction,
      setIsTestPreviewVisible: setIsTestPreviewVisibleAction,
    }
  )
)

export default enhance(TableView)

const SpinContainer = styled.div`
  height: 300px;
`
