import { all, call, put, takeLatest } from 'redux-saga/effects'
import { get } from 'lodash'

import { notification } from '@edulastic/common'
import { reportsApi } from '@edulastic/api'

import { actions } from './actionReducers'
import { getStudentsList } from '../../../../common/util'

function* fetchFilterDataSaga({ payload }) {
  try {
    const filtersDataResponse = yield call(
      reportsApi.fetchSPRFilterData,
      payload
    )
    yield put(
      actions.fetchFilterDataSuccess({
        filtersData: filtersDataResponse,
      })
    )
  } catch (error) {
    const msg =
      'Error getting filter data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchFilterDataError({ error: true }))
  }
}

function* fetchStudentListSaga({ payload }) {
  try {
    const result = yield call(reportsApi.fetchStudentList, payload)
    const _studentList = getStudentsList(get(result, 'data.result', []))
    yield put(
      actions.fetchStudentListSuccess({
        studentListQuery: payload,
        studentList: _studentList,
      })
    )
  } catch (err) {
    const msg = 'Unable to fetch students list.'
    notification({ type: 'error', msg })
    yield put(actions.fetchStudentListError({ error: true }))
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(actions.fetchFilterData, fetchFilterDataSaga),
    takeLatest(actions.fetchStudentList, fetchStudentListSaga),
  ])
}
