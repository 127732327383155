import React, { useState } from 'react'
import produce from 'immer'
import { Tooltip, Select } from 'antd'
import { EduButton, helpers, Stimulus } from '@edulastic/common'
import {
  IconPencilEdit,
  IconDownwardArrow,
  IconPlusCircleThemeColor,
} from '@edulastic/icons'
import { lightRed2 } from '@edulastic/colors'

import { sanitizeHtml } from '@edulastic/common/src/utils/html'
import {
  ContentBody,
  GroupField,
  ItemTag,
  Label,
  SectionAdaptiveTestPanelHeading,
  PanelStyled,
  QuestionTagsContainer,
  QuestionTagsWrapper,
  SectionsAdaptiveTestContainer,
  SectionsAdaptiveTestSettingsText,
  SectionsAdaptiveTestTextContainer,
  StyledIconInfo,
  StyledSectionsAdaptiveTestSettingsContainer,
  StyledVerticalArrowContainer,
  StyledCollapse,
  StyledMinutesLabelContainer,
  StyledInputContainer,
  StyledSettingsInputContainer,
  StyledThresholdText,
  StyledSelect,
  StyledNextSectionHighlighterContainer,
  StyledDotDivider,
  StyledWarningText,
  StyledIconItemCross,
  StyledSectionSettingsContainer,
  StyledSectionSettingLabel,
  StyledSectionLabelContainer,
  StyledEditIconContainer,
  StyledNumberInput,
  StyledCreateRuleContainer,
  StyledCreateRuleLeftContainer,
  StyledCreateRuleText,
} from './styled'
import { adaptiveTestThresholdTypes, adaptiveTestSettings } from './constants'
import { convertTimeInMinutesFromMilliseconds } from '../../../../assessment/utils/timeUtils'
import { StyledTooltip } from '../../../Reports/common/styled'
import { validateAndGetDefaultThresholdValue } from './SectionsAdaptiveTest/utils'

const SectionsAdaptiveTestGrouptems = ({
  currentGroupIndex,
  itemGroups = [],
  setTestData,
  adaptiveDelivery,
  handleEditGroup,
  handleSelectItems,
  handleRemoveItemFromGroup,
  allFieldsDisabled,
  isRegradeFlow,
  isEditable,
  isSectionsAdaptiveTestCreateRulesButtonVisible: isCreateRuleButtonVisible,
  onCreateRulesButtonClick,
  selectedAdaptiveSetting,
}) => {
  const [activePanel, setActivePanel] = useState(1)

  const baseSection = itemGroups[0]
  const easySection = itemGroups[1]
  const hardSection = itemGroups[2]
  const numberOfItemsInBaseSection = baseSection?.items?.length
  const easySectionItemsCount = easySection?.items?.length
  const hardSectionItemsCount = hardSection?.items?.length
  const {
    baseSectionMaxItemsCount,
    threshold: {
      value: thresholdValue,
      thresholdSectionMaxItemsCount,
      thresholdType,
    } = {},
  } = adaptiveDelivery || {}

  const { settings: { timeLimit: baseSectionTimeLimit } = {} } =
    baseSection || {}
  const { settings: { timeLimit: nextSectionTimeLimit } = {} } =
    easySection || {}

  const baseSectionTimeLimitInMins = convertTimeInMinutesFromMilliseconds(
    baseSectionTimeLimit
  )
  const nextSectionTimeLimitInMins = convertTimeInMinutesFromMilliseconds(
    nextSectionTimeLimit
  )

  const handleTimeLimitChange = (isBaseSection, timeLimitInMins) => {
    if (typeof timeLimitInMins !== 'number' || timeLimitInMins < 0) {
      timeLimitInMins = 0
    }

    const updatedItemGroups = produce(itemGroups, (draft) => {
      if (isBaseSection) {
        draft[0].settings = {
          ...(draft[0]?.settings || {}),
          timeLimit: timeLimitInMins * 60 * 1000,
        }
      } else {
        draft[1].settings = {
          ...(draft[1]?.settings || {}),
          timeLimit: timeLimitInMins * 60 * 1000,
        }
        draft[2].settings = {
          ...(draft[2]?.settings || {}),
          timeLimit: timeLimitInMins * 60 * 1000,
        }
      }
    })

    const totalAllowedTime =
      updatedItemGroups?.[0]?.settings?.timeLimit +
      updatedItemGroups?.[1]?.settings?.timeLimit
    const isTimedTest = totalAllowedTime > 0

    setTestData({
      itemGroups: updatedItemGroups,
      timedAssignment: isTimedTest,
      ...(isTimedTest
        ? { allowedTime: totalAllowedTime }
        : { allowedTime: undefined }),
    })
  }
  const handleScaledScore = (index, key, score) => {
    if (key === 'min' && (score < 0 || typeof score !== 'number')) {
      score = 0
    } else if (key === 'max' && (score < 10 || typeof score !== 'number')) {
      score = 10
    }

    const updatedItemGroups = produce(itemGroups, (draft) => {
      draft[index].settings = {
        ...(draft[index]?.settings || {}),
        scaledScore: {
          ...draft[index]?.settings?.scaledScore,
          [key]: score,
        },
      }
    })

    setTestData({
      itemGroups: updatedItemGroups,
    })
  }

  const onChangeThresholdValue = (_thresholdValue) => {
    const validatedThresholdValue = validateAndGetDefaultThresholdValue({
      thresholdValue: _thresholdValue,
      thresholdType,
      baseSectionMaxItemsCount,
    })
    setTestData({
      adaptiveDelivery: produce(adaptiveDelivery, (draft) => {
        draft.threshold.value = validatedThresholdValue
      }),
    })
  }

  const onChangeItemCount = (isBaseSection, itemCount) => {
    const itemsCountInSection = isBaseSection
      ? numberOfItemsInBaseSection
      : Math.max(easySectionItemsCount, hardSectionItemsCount)

    if (typeof itemCount !== 'number' || itemCount < 1) {
      itemCount = 1
    }

    itemCount = Math.floor(itemCount)

    if (itemsCountInSection && itemCount < itemsCountInSection) {
      return
    }

    setTestData({
      adaptiveDelivery: produce(adaptiveDelivery, (draft) => {
        if (isBaseSection) {
          draft.baseSectionMaxItemsCount = itemCount
        } else {
          draft.threshold.thresholdSectionMaxItemsCount = itemCount
        }
      }),
    })
  }

  const onThresholdTypeChange = (_thresholdType) => {
    const validatedThresholdValue = validateAndGetDefaultThresholdValue({
      thresholdValue:
        adaptiveTestSettings[selectedAdaptiveSetting]?.threshold?.[
          _thresholdType
        ],
      thresholdType: _thresholdType,
      baseSectionMaxItemsCount,
    })
    setTestData({
      adaptiveDelivery: produce(adaptiveDelivery, (draft) => {
        draft.threshold.thresholdType = _thresholdType
        draft.threshold.value = validatedThresholdValue
      }),
    })
  }

  const onActivePanelsChange = (sectionPanelIndex) => {
    if (activePanel !== sectionPanelIndex) {
      setActivePanel(sectionPanelIndex)
    } else {
      setActivePanel(0)
    }
  }

  const onClickEditGroup = (e, itemGroup, index) => {
    if (activePanel !== index + 1) {
      onActivePanelsChange(index + 1)
    }
    handleEditGroup(e, itemGroup, index)
  }

  const getSectionSelectItemsDisabledAndToolTipMessage = ({
    sectionItemCount,
    sectionMaxItemCount,
    sectionIndex,
  }) => {
    const isDisabled =
      allFieldsDisabled ||
      currentGroupIndex !== sectionIndex ||
      !sectionMaxItemCount ||
      sectionItemCount >= (sectionMaxItemCount || 0)

    let tooltipMessage = null

    if (!isEditable) {
      tooltipMessage = 'Edit test to select items'
    } else if (isRegradeFlow) {
      tooltipMessage = 'Edit and Regrade not yet supported for Adaptive Tests'
    } else if (currentGroupIndex !== sectionIndex) {
      tooltipMessage = 'Click on edit to select items'
    } else if (!sectionMaxItemCount) {
      tooltipMessage =
        'Please first select number of items required in this section to enable item selection'
    } else if (sectionMaxItemCount && sectionItemCount >= sectionMaxItemCount) {
      tooltipMessage =
        'Required number of items are already selected. Please deselect to add more.'
    }

    return [isDisabled, tooltipMessage]
  }

  const getThresholdButtonText = (index) => {
    const isEasy = index === 1
    if (thresholdValue) {
      const isPercent =
        thresholdType === adaptiveTestThresholdTypes.PERCENTAGE.value
      return `${isEasy ? 'Easy' : 'Hard'} (${
        isEasy ? '<' : '>='
      }${thresholdValue}${isPercent ? '%' : ' Correct'})`
    }
    return `${isEasy ? 'Easy' : 'Hard'}`
  }

  return (
    <SectionsAdaptiveTestContainer>
      <SectionsAdaptiveTestTextContainer mb="15px" mt="20px">
        <SectionsAdaptiveTestSettingsText mr="5px">
          Common Items{' '}
        </SectionsAdaptiveTestSettingsText>
        <Tooltip title="Section 1 will be delivered to all students. Based on the performance on section 1 based on the Threshold, either Section 2 easy or Section 2 hard will be delivered to the student.">
          <StyledIconInfo />
        </Tooltip>
      </SectionsAdaptiveTestTextContainer>

      {itemGroups.map((itemGroup, index) => {
        const sectionMaxItemCount =
          index === 0 ? baseSectionMaxItemsCount : thresholdSectionMaxItemsCount
        const [
          isSelectItemsDisabled,
          selectItemsToolTipMessage,
        ] = getSectionSelectItemsDisabledAndToolTipMessage({
          sectionItemCount: itemGroup.items.length,
          sectionMaxItemCount,
          sectionIndex: index,
        })
        const itemsCountInSection = itemGroup.items.length
        const scaledScoreMin = itemGroup?.settings?.scaledScore?.min
        const scaledScoreMax = itemGroup?.settings?.scaledScore?.max
        return (
          <>
            {(index === 0 || (index !== 0 && !isCreateRuleButtonVisible)) && (
              <StyledCollapse
                marginTop={index === 1 ? '0px' : null}
                activeKey={activePanel}
                onChange={() => onActivePanelsChange(index + 1)}
                expandIconPosition="right"
              >
                <PanelStyled
                  padding="10px 45px 10px 10px"
                  header={[
                    <SectionAdaptiveTestPanelHeading>
                      <StyledSectionLabelContainer>
                        <StyledSectionSettingLabel>
                          {index === 0
                            ? itemGroup.groupName
                            : itemGroup.groupName?.split('-')?.[0]?.trim() ||
                              ''}
                        </StyledSectionSettingLabel>
                        {index !== 0 && (
                          <StyledNextSectionHighlighterContainer>
                            <span>{getThresholdButtonText(index)}</span>
                          </StyledNextSectionHighlighterContainer>
                        )}
                      </StyledSectionLabelContainer>
                      <StyledSectionSettingsContainer>
                        {index === 0 ? (
                          currentGroupIndex !== 0 ? (
                            <>
                              {!!baseSectionTimeLimitInMins && (
                                <StyledSectionSettingLabel>
                                  {baseSectionTimeLimitInMins} mins
                                </StyledSectionSettingLabel>
                              )}
                              {!!baseSectionTimeLimitInMins &&
                                !!baseSectionMaxItemsCount && (
                                  <StyledDotDivider />
                                )}
                              {!!baseSectionMaxItemsCount && (
                                <StyledSectionSettingLabel>
                                  {baseSectionMaxItemsCount} Items
                                </StyledSectionSettingLabel>
                              )}
                              {!!baseSectionMaxItemsCount &&
                                !!scaledScoreMin &&
                                !!scaledScoreMax && <StyledDotDivider />}
                              {!!scaledScoreMin && !!scaledScoreMax && (
                                <>
                                  <StyledSectionSettingLabel>
                                    Scaled Score: {scaledScoreMin} -{' '}
                                    {scaledScoreMax}
                                  </StyledSectionSettingLabel>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <StyledSettingsInputContainer>
                                <StyledSectionSettingLabel>
                                  Time Limit
                                </StyledSectionSettingLabel>
                                <StyledInputContainer
                                  onClick={(e) => e?.stopPropagation()}
                                >
                                  <StyledNumberInput
                                    value={baseSectionTimeLimitInMins}
                                    onChange={(value) =>
                                      handleTimeLimitChange(true, value)
                                    }
                                    disabled={allFieldsDisabled}
                                    placeholder="Eg: 60"
                                    width="70px"
                                    bg="unset"
                                    noBorder
                                    height="28px"
                                    data-cy="baseSectionTimeLimitInput"
                                    min={0}
                                    max={300}
                                  />
                                  <StyledMinutesLabelContainer>
                                    min
                                  </StyledMinutesLabelContainer>
                                </StyledInputContainer>
                              </StyledSettingsInputContainer>
                              <StyledSettingsInputContainer
                                onClick={(e) => e?.stopPropagation()}
                              >
                                <StyledSectionSettingLabel fontWeight="600">
                                  Items Required{' '}
                                  <span style={{ color: lightRed2 }}>*</span>
                                </StyledSectionSettingLabel>
                                <StyledInputContainer>
                                  <StyledNumberInput
                                    value={baseSectionMaxItemsCount}
                                    onChange={(value) =>
                                      onChangeItemCount(true, value)
                                    }
                                    min={0}
                                    disabled={allFieldsDisabled}
                                    width="70px"
                                    bg="unset"
                                    noBorder
                                    height="28px"
                                    data-cy="baseSectionMaxItemsCountInput"
                                  />
                                </StyledInputContainer>
                                <StyledSettingsInputContainer
                                  onClick={(e) => e?.stopPropagation()}
                                >
                                  <StyledSectionSettingLabel fontWeight="600">
                                    Scaled Score{' '}
                                    <span style={{ color: lightRed2 }}>*</span>
                                  </StyledSectionSettingLabel>
                                  <StyledInputContainer>
                                    <StyledNumberInput
                                      value={scaledScoreMin}
                                      onChange={(value) =>
                                        handleScaledScore(index, 'min', value)
                                      }
                                      min={0}
                                      disabled={allFieldsDisabled}
                                      width="60px"
                                      bg="unset"
                                      noBorder
                                      height="28px"
                                      placeholder="Min"
                                      data-cy="baseSectionScaledScoreMinInput"
                                    />
                                    <span>-</span>
                                    <StyledNumberInput
                                      value={scaledScoreMax}
                                      onChange={(value) =>
                                        handleScaledScore(index, 'max', value)
                                      }
                                      disabled={allFieldsDisabled}
                                      min={0}
                                      width="60px"
                                      bg="unset"
                                      noBorder
                                      height="28px"
                                      placeholder="Max"
                                      data-cy="baseSectionScaledScoreMaxInput"
                                    />
                                  </StyledInputContainer>
                                </StyledSettingsInputContainer>
                              </StyledSettingsInputContainer>
                            </>
                          )
                        ) : currentGroupIndex === index ? (
                          <StyledSettingsInputContainer
                            onClick={(e) => e?.stopPropagation()}
                          >
                            <StyledSectionSettingLabel fontWeight="600">
                              Scaled Score{' '}
                              <span style={{ color: lightRed2 }}>*</span>
                            </StyledSectionSettingLabel>
                            <StyledInputContainer>
                              <StyledNumberInput
                                value={scaledScoreMin}
                                onChange={(value) =>
                                  handleScaledScore(index, 'min', value)
                                }
                                min={0}
                                disabled={allFieldsDisabled}
                                width="60px"
                                bg="unset"
                                noBorder
                                height="28px"
                                placeholder="Min"
                              />
                              <span>-</span>
                              <StyledNumberInput
                                value={scaledScoreMax}
                                onChange={(value) =>
                                  handleScaledScore(index, 'max', value)
                                }
                                min={0}
                                disabled={allFieldsDisabled}
                                width="60px"
                                bg="unset"
                                noBorder
                                height="28px"
                                placeholder="Max"
                              />
                            </StyledInputContainer>
                          </StyledSettingsInputContainer>
                        ) : (
                          !!scaledScoreMin &&
                          !!scaledScoreMax && (
                            <StyledSectionSettingLabel>
                              Scaled Score: {scaledScoreMin} - {scaledScoreMax}
                            </StyledSectionSettingLabel>
                          )
                        )}
                        {currentGroupIndex !== index && (
                          <StyledEditIconContainer
                            title="edit"
                            onClick={(e) =>
                              onClickEditGroup(e, itemGroup, index)
                            }
                            disabled={allFieldsDisabled}
                            data-cy={`editSection-${itemGroup.groupName}`}
                          >
                            <IconPencilEdit />
                          </StyledEditIconContainer>
                        )}
                      </StyledSectionSettingsContainer>
                    </SectionAdaptiveTestPanelHeading>,
                  ]}
                  disabled={allFieldsDisabled}
                  key={index + 1}
                >
                  <ContentBody>
                    <GroupField marginBottom="10px">
                      <Label
                        data-cy={`selectedItemsCountInSection-${itemGroup.groupName}`}
                      >
                        Selected Items{' '}
                        {`(${itemsCountInSection}/${sectionMaxItemCount || 0})`}{' '}
                        <span style={{ color: lightRed2 }}>*</span>
                      </Label>
                      <QuestionTagsWrapper gap="20px">
                        <QuestionTagsContainer
                          width="60%"
                          data-cy={`item-container-${itemGroup.groupName}`}
                        >
                          {itemGroup.items
                            .map(({ _id, data }) => ({
                              idLabel: _id.substring(
                                _id.length,
                                _id.length - 6
                              ),
                              _id,
                              stimulus: data?.questions?.[0]?.stimulus || '',
                            }))
                            .map((item) => (
                              <StyledTooltip
                                title={
                                  <Stimulus
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(
                                        helpers.sanitizeForReview(
                                          item?.stimulus || ''
                                        )
                                      ),
                                    }}
                                  />
                                }
                              >
                                <ItemTag cursor="pointer" data-cy="itemIdLabel">
                                  {item.idLabel}
                                  {currentGroupIndex === index && (
                                    <StyledIconItemCross
                                      onClick={() =>
                                        handleRemoveItemFromGroup(
                                          item._id,
                                          index
                                        )
                                      }
                                      disabled={allFieldsDisabled}
                                    />
                                  )}
                                </ItemTag>
                              </StyledTooltip>
                            ))}
                        </QuestionTagsContainer>
                        <Tooltip
                          title={
                            isSelectItemsDisabled
                              ? selectItemsToolTipMessage
                              : null
                          }
                        >
                          <span>
                            <EduButton
                              height="40px"
                              isGhost
                              onClick={handleSelectItems}
                              disabled={isSelectItemsDisabled}
                              data-cy={`selectItemButton-${itemGroup.groupName}`}
                            >
                              Select Items
                            </EduButton>
                          </span>
                        </Tooltip>
                      </QuestionTagsWrapper>
                    </GroupField>
                    {!!sectionMaxItemCount &&
                      itemsCountInSection < sectionMaxItemCount && (
                        <StyledWarningText
                          data-cy={`remainingItemToSelect-${itemGroup.groupName}`}
                        >
                          {`${sectionMaxItemCount - itemsCountInSection} ${
                            itemsCountInSection ? 'more ' : ''
                          }`}
                          item(s) need to be selected
                        </StyledWarningText>
                      )}
                  </ContentBody>
                </PanelStyled>
              </StyledCollapse>
            )}

            {index === 0 && (
              <>
                <StyledVerticalArrowContainer>
                  <IconDownwardArrow />
                </StyledVerticalArrowContainer>
                <StyledSectionsAdaptiveTestSettingsContainer>
                  {isCreateRuleButtonVisible ? (
                    <StyledCreateRuleContainer
                      onClick={onCreateRulesButtonClick}
                      data-cy="createRuleContainer"
                    >
                      <StyledCreateRuleLeftContainer>
                        <IconPlusCircleThemeColor />
                        <StyledCreateRuleText>
                          Create rule to decide next section for students
                          (Easy/Hard)
                        </StyledCreateRuleText>
                      </StyledCreateRuleLeftContainer>
                      <EduButton
                        height="32px"
                        onClick={onCreateRulesButtonClick}
                        data-cy="createRuleButton"
                      >
                        ENTER
                      </EduButton>
                    </StyledCreateRuleContainer>
                  ) : (
                    <>
                      {' '}
                      <StyledSettingsInputContainer gap="5px">
                        <SectionsAdaptiveTestSettingsText>
                          Adaptive Settings{' '}
                        </SectionsAdaptiveTestSettingsText>
                        <Tooltip title="Customize the test’s adaptiveness by setting a section threshold (percentage or number of correct items) to determine who receives easier or harder sections. Specify the section time limit and number of items.">
                          <StyledIconInfo data-cy="adaptiveTestSettingsInfoIcon" />
                        </Tooltip>
                      </StyledSettingsInputContainer>
                      <StyledSettingsInputContainer>
                        <StyledSettingsInputContainer
                          flexDirection="column"
                          gap="0px"
                          alignItems="start"
                        >
                          <SectionsAdaptiveTestSettingsText>
                            Threshold<span style={{ color: lightRed2 }}>*</span>
                          </SectionsAdaptiveTestSettingsText>
                          <StyledThresholdText>
                            (based on section 1 score)
                          </StyledThresholdText>
                        </StyledSettingsInputContainer>
                        <StyledInputContainer>
                          <StyledNumberInput
                            value={thresholdValue}
                            onChange={onChangeThresholdValue}
                            disabled={allFieldsDisabled}
                            width="70px"
                            bg="unset"
                            noBorder
                            height="28px"
                            min={1}
                            max={
                              thresholdType === 'PERCENTAGE'
                                ? 99
                                : baseSectionMaxItemsCount
                            }
                            data-cy="thresholdValueInput"
                          />
                          <StyledSelect
                            width="fit-content"
                            value={thresholdType}
                            onChange={onThresholdTypeChange}
                            disabled={allFieldsDisabled}
                            defaultValue={
                              adaptiveTestThresholdTypes.PERCENTAGE.value
                            }
                            data-cy="thresholdTypeSelect"
                          >
                            {Object.keys(adaptiveTestThresholdTypes).map(
                              (key) => (
                                <Select.Option
                                  value={adaptiveTestThresholdTypes[key].value}
                                  aria-label={
                                    adaptiveTestThresholdTypes[key].label
                                  }
                                >
                                  {adaptiveTestThresholdTypes[key].label}
                                </Select.Option>
                              )
                            )}
                          </StyledSelect>
                        </StyledInputContainer>
                      </StyledSettingsInputContainer>
                      <StyledSettingsInputContainer>
                        <SectionsAdaptiveTestSettingsText>
                          Time Limit
                        </SectionsAdaptiveTestSettingsText>
                        <StyledInputContainer>
                          <StyledNumberInput
                            value={nextSectionTimeLimitInMins}
                            onChange={(value) =>
                              handleTimeLimitChange(false, value)
                            }
                            disabled={allFieldsDisabled}
                            placeholder="Eg: 60"
                            width="70px"
                            bg="unset"
                            noBorder
                            height="28px"
                            data-cy="nextSectionTimeLimitInput"
                            min={0}
                            max={300}
                          />
                          <StyledMinutesLabelContainer>
                            min
                          </StyledMinutesLabelContainer>
                        </StyledInputContainer>
                      </StyledSettingsInputContainer>
                      <StyledSettingsInputContainer>
                        <SectionsAdaptiveTestSettingsText>
                          Items Required{' '}
                          <span style={{ color: lightRed2 }}>*</span>
                        </SectionsAdaptiveTestSettingsText>
                        <StyledInputContainer>
                          <StyledNumberInput
                            value={thresholdSectionMaxItemsCount}
                            onChange={(value) =>
                              onChangeItemCount(false, value)
                            }
                            disabled={allFieldsDisabled}
                            width="70px"
                            bg="unset"
                            noBorder
                            height="28px"
                            data-cy="nextSectionMaxItemsCountInput"
                          />
                        </StyledInputContainer>
                      </StyledSettingsInputContainer>
                    </>
                  )}
                </StyledSectionsAdaptiveTestSettingsContainer>
                {!isCreateRuleButtonVisible && (
                  <StyledVerticalArrowContainer>
                    <IconDownwardArrow />
                  </StyledVerticalArrowContainer>
                )}
              </>
            )}
          </>
        )
      })}
    </SectionsAdaptiveTestContainer>
  )
}

export default SectionsAdaptiveTestGrouptems
