import React, { useState, useEffect, useRef } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Stimulus } from '@edulastic/common'
import { mapValues, isEqual } from 'lodash'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'
import {
  buildCriteriaRatingMap,
  buildRubricFeedback,
  calculateScore,
} from './helper'
import {
  CriteriaSection,
  RatingSection,
  Container,
  CriteriaWrapper,
  RatingContainer,
  RatingScrollContainer,
  PrevBtn,
  NextBtn,
  StandardsSection,
} from './PreviewRubricStyledComponents'
import { ScrollableDescriptionWrapper } from '../../styled'
import Tags from '../../../src/components/common/Tags'

const RatingCards = ({
  criteria,
  isDisabled,
  selectedRatings,
  handleRatingSelection,
}) => {
  const [hasScroll, setHasScroll] = useState()
  const contRef = useRef()
  const psRef = contRef.current?._ps || {}
  const timerRef = useRef()

  const handleClickRating = (ratingId) => () => {
    !isDisabled && handleRatingSelection(criteria.id, ratingId)
  }

  const handleMouseUp = () => {
    clearInterval(timerRef.current)
  }

  const handleMouseDown = (dir) => () => {
    if (psRef?.element && psRef?.reach?.x !== dir) {
      timerRef.current = setInterval(() => {
        if (!psRef || !psRef?.element) {
          return clearInterval(timerRef.current)
        }
        psRef.element.scrollBy({
          left: dir === 'end' ? 10 : -10,
        })
      }, 10)
    }
  }

  useEffect(() => {
    if (psRef) {
      setHasScroll(psRef?.contentWidth > psRef?.containerWidth)
    } else {
      setHasScroll(false)
    }
  }, [psRef?.contentWidth, psRef?.containerWidth])

  return (
    <RatingContainer>
      {hasScroll && (
        <PrevBtn
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDown('start')}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </PrevBtn>
      )}
      <RatingScrollContainer
        options={{
          suppressScrollY: true,
          useBothWheelAxes: true,
        }}
        ref={contRef}
      >
        {criteria.ratings.map((rating) => (
          <RatingSection
            data-cy="ratingCards"
            onClick={handleClickRating(rating.id)}
            selected={selectedRatings[criteria.id] == rating.id}
            isDisabled={isDisabled}
            key={rating.id}
          >
            <div>
              <div data-cy="ratingName">{rating.name}</div>
              <div
                className="points"
                data-cy="ratingPoint"
              >{`${rating.points} pts`}</div>
            </div>
            <ScrollableDescriptionWrapper
              maxHeight="50vh"
              margin="0 -10px -5px 0"
            >
              <Stimulus
                data-cy="ratingDesc"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(rating.desc),
                }}
              />
            </ScrollableDescriptionWrapper>
          </RatingSection>
        ))}
      </RatingScrollContainer>
      {hasScroll && (
        <NextBtn onMouseUp={handleMouseUp} onMouseDown={handleMouseDown('end')}>
          <FontAwesomeIcon icon={faAngleRight} />
        </NextBtn>
      )}
    </RatingContainer>
  )
}

const PreviewRubricTable = ({
  rubric,
  handleChange,
  rubricFeedback,
  isDisabled = false,
  validateRubricResponse = false,
}) => {
  const [prevRubricFeedback, setPrevRubricFeedback] = useState()
  const [selectedRatings, setSelectedRatings] = useState({})

  const handleRatingSelection = (criteriaId, ratingId) => {
    const _selectedRatings = { ...selectedRatings }
    _selectedRatings[criteriaId] = ratingId
    setSelectedRatings(_selectedRatings)
    const _rubricFeedback = buildRubricFeedback(
      _selectedRatings,
      buildCriteriaRatingMap(rubric?.criteria)
    )

    handleChange({
      score: calculateScore(rubric, _rubricFeedback),
      rubricFeedback: _rubricFeedback,
      rubricId: rubric._id,
    })
  }

  useEffect(() => {
    if (!isEqual(prevRubricFeedback, rubricFeedback)) {
      setPrevRubricFeedback(rubricFeedback)
      if (rubricFeedback) {
        setSelectedRatings(mapValues(rubricFeedback, (val) => val?.ratingId))
      }
    }
  }, [rubricFeedback, isDisabled])

  const getContent = () =>
    rubric?.criteria?.map((c, i) => (
      <CriteriaWrapper
        data-cy="previewCriteria"
        key={c?.id || i}
        showError={
          !Object.keys(selectedRatings).includes(c?.id) &&
          validateRubricResponse
        }
      >
        <CriteriaSection>
          <div data-cy="criteriaName">{c?.name}</div>
        </CriteriaSection>
        <StandardsSection>
          <Tags
            dataCy="selectedRubric"
            dataKey="identifier"
            labelStyle={{
              fontSize: '12px',
              fontWeight: 600,
              padding: '3px 8px',
              height: 'auto',
            }}
            show={3}
            tags={c.alignment?.standards || []}
          />
        </StandardsSection>
        <RatingCards
          criteria={c}
          isDisabled={isDisabled}
          selectedRatings={selectedRatings}
          handleRatingSelection={handleRatingSelection}
        />
      </CriteriaWrapper>
    ))

  return (
    <>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
        }}
        style={{ maxHeight: '600px', width: '100%' }}
      >
        <Container>{getContent()}</Container>
      </PerfectScrollbar>
    </>
  )
}

export default PreviewRubricTable
