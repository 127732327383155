import { SelectInputStyled } from '@edulastic/common'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ClassTypeDropDown, FilterContainer } from './styled'
import { getUserId, getUserOrgId } from '../../../../../../src/selectors/user'
import {
  getClassFilters,
  receiveTeacherDashboardAction,
  setClassFiltersAction,
} from '../../../../../ducks'
import { myClassFilters } from '../../../utils'

const { Option } = SelectInputStyled

const Filters = ({
  userId,
  districtId,
  getTeacherDashboard,
  setClassFilters,
  classFilters,
}) => {
  useEffect(() => {
    setClassFilters({
      ...classFilters,
      classType:
        localStorage.getItem(
          `author:dashboard:classFilter:${userId}:${districtId}`
        ) || 'ALL_CLASSES',
    })
  }, [])

  const handleClassTypeChange = (value) => {
    localStorage.setItem(
      `author:dashboard:classFilter:${userId}:${districtId}`,
      value
    )
    setClassFilters({
      ...classFilters,
      classType: value,
    })

    getTeacherDashboard({
      background: true,
    })
  }
  return (
    <FilterContainer>
      <ClassTypeDropDown
        data-cy="favouritesDropdown"
        value={classFilters.classType}
        onChange={handleClassTypeChange}
        width="200px"
        height="25px"
        margin="0px 10px"
        data-testid="favouritesDropdown"
        aria-label="Select Class Filter"
      >
        {Object.keys(myClassFilters).map((key) => (
          <Option data-cy={key} key={key} value={key} data-testid={key}>
            {myClassFilters[key]}
          </Option>
        ))}
      </ClassTypeDropDown>
    </FilterContainer>
  )
}

export default connect(
  (state) => ({
    userId: getUserId(state),
    districtId: getUserOrgId(state),
    classFilters: getClassFilters(state),
  }),
  {
    setClassFilters: setClassFiltersAction,
    getTeacherDashboard: receiveTeacherDashboardAction,
  }
)(Filters)
