import React from 'react'
import PropTypes from 'prop-types'
import { MathSpan } from '@edulastic/common'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'

const AnswerContent = ({ userAnswer, isPrintPreview }) => (
  <MathSpan
    isPrintPreview={isPrintPreview}
    dangerouslySetInnerHTML={{ __html: sanitizeHtml(userAnswer) }}
  />
)

AnswerContent.propTypes = {
  userAnswer: PropTypes.string.isRequired,
}

export default AnswerContent
