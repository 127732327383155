import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Popover, Tooltip } from 'antd'
import { white, themeColor, darkOrange1 } from '@edulastic/colors'
import { EduButton, FlexContainer, MainHeader } from '@edulastic/common'
import {
  IconClockDashboard,
  IconHangouts,
  IconShoppingBag,
  IconInfoCircle,
} from '@edulastic/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { get } from 'lodash'
import { segmentApi } from '@edulastic/api'

import { signUpState } from '@edulastic/constants'
import {
  ONE_MONTH_IN_MILLISECONDS,
  TEN_DAYS_IN_MILLISECONDS,
} from '@edulastic/constants/const/common'
import {
  getShowDiscountOfferSelector,
  getSubscriptionLoading,
  slice,
} from '../../../Subscription/ducks'
// TODO: Change to SVG
import IMG from '../../../Subscription/static/6.png'
import {
  PopoverCancel,
  PopoverDetail,
  PopoverTitle,
  PopoverWrapper,
  UpgradeBtn,
  CloseButton,
} from './styled'
import { launchHangoutOpen } from '../../ducks'
import {
  getUserSelector,
  getInterestedGradesSelector,
  getInterestedSubjectsSelector,
  currentDistrictInstitutionIds,
  isGcpsDistrictSelector,
  getUserRole,
} from '../../../src/selectors/user'
import AuthorCompleteSignupButton from '../../../../common/components/AuthorCompleteSignupButton'
import {
  getCanvasCourseListRequestAction,
  getCanvasSectionListRequestAction,
  getCleverClassListSelector,
  setShowCanvasSyncModalAction,
  setShowCleverSyncModalAction,
  syncClassesWithCleverAction,
} from '../../../ManageClass/ducks'

import CanvasClassSelectModal from '../../../ManageClass/components/ClassListContainer/CanvasClassSelectModal'
import ClassSelectModal from '../../../ManageClass/components/ClassListContainer/ClassSelectModal'
import { TooltipStyled } from '../Showcase/components/Myclasses/components/CardAssignmentContent/styled'
import { getFormattedCurriculumsSelector } from '../../../src/selectors/dictionaries'
import { getSelectedItemSelector } from '../../../TestPage/components/AddItems/ducks'
import { ItemsAmount } from '../../../ItemList/components/CartButton/styled'
import { createTestFromCartAction } from '../../../ItemList/ducks'
import { FEATURE_BANNER, getOriginFromUrl } from '../../../FeatureBanner/utils'
import SearchBox from './SearchBox'
import CreateTestButton from './CreateTest'
import DashboardToggle from './DashboardToggle'

const getContent = ({
  setvisible,
  needsRenewal,
  isPremiumUser,
  isPremiumTrialUsed,
  handleShowTrialModal,
  showDiscountOffer,
}) => {
  const Content = () => {
    if (!isPremiumUser && !isPremiumTrialUsed && !showDiscountOffer) {
      return (
        <>
          <Link to="/author/subscription">
            <PopoverCancel data-cy="upgradeButton">UPGRADE</PopoverCancel>
          </Link>
          <AuthorCompleteSignupButton
            renderButton={(handleClick) => (
              <UpgradeBtn data-cy="freeTrialButton" onClick={handleClick}>
                TRY FOR FREE
              </UpgradeBtn>
            )}
            onClick={handleShowTrialModal}
          />
        </>
      )
    }
    return (
      <>
        <PopoverCancel data-cy="cancelButton" onClick={() => setvisible(false)}>
          {' '}
          NO, THANKS
        </PopoverCancel>
        <Link to="/author/subscription">
          <UpgradeBtn data-cy="upgradeButton">
            {needsRenewal ? 'RENEW NOW' : 'UPGRADE'}
          </UpgradeBtn>
        </Link>
      </>
    )
  }

  return (
    <FlexContainer width="475px" alignItems="flex-start">
      <CloseButton onClick={() => setvisible(false)}>x</CloseButton>
      <img src={IMG} width="165" height="135" alt="subscription" />
      <FlexContainer
        flexDirection="column"
        width="280px"
        padding="15px 0 0 6px"
      >
        <PopoverTitle data-cy="upgradeTitle">
          Access Additional Features
        </PopoverTitle>
        <PopoverDetail data-cy="upgradeDescription">
          Get additional reports, options to assist students, collaborate with
          colleagues, anti-cheating tools and more.
        </PopoverDetail>
        <FlexContainer padding="15px 0 15px 0" width="100%">
          {Content()}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

const NewHeaderSection = ({
  user,
  isSubscriptionExpired = false,
  t,
  openLaunchHangout,
  subscription,
  setShowCleverSyncModal,
  courseList,
  loadingCleverClassList,
  cleverClassList,
  getStandardsListBySubject,
  syncCleverClassList,
  defaultGrades = [],
  defaultSubjects = [],
  institutionIds,
  canvasCourseList,
  canvasSectionList,
  getCanvasCourseListRequest,
  getCanvasSectionListRequest,
  showCleverSyncModal,
  classData,
  districtPolicy,
  schoolPolicy,
  setShowHeaderTrialModal,
  isPremiumTrialUsed,
  showCanvasSyncModal,
  setShowCanvasSyncModal,
  history,
  selectedItems,
  createTestFromCart,
  userRole,
  showDiscountOffer,
  isGcpsDistrict,
  loading,
}) => {
  const { subEndDate, subType } = subscription || {}

  const { user: userInfo } = user
  const { currentSignUpState } = userInfo

  const [visible, setvisible] = useState(false)

  const trackClick = (event) => () =>
    segmentApi.trackUserClick({
      user: user.user,
      data: { event },
    })
  const handelDiscountClick = () => {
    const origin = getOriginFromUrl()
    segmentApi.genericEventTrack(FEATURE_BANNER, {
      title: 'Discount Price',
      origin,
    })
    history.push('/author/subscription')
  }
  const launchHangout = () => {
    openLaunchHangout()
  }

  const isPremiumUser = user.user?.features?.premium
  /**
   *  a user is paid premium user if
   *  - subType exists and
   *  - premium is not through trial ie, only - (enterprise, premium, partial_premium) and
   *  - is partial premium user & premium is true
   *
   * TODO: refactor and define this at the top level
   */
  const isPaidPremium = !(
    !subType ||
    subType === 'TRIAL_PREMIUM' ||
    (subType === 'partial_premium' && !isPremiumUser)
  )

  const isAboutToExpire = subEndDate
    ? Date.now() + ONE_MONTH_IN_MILLISECONDS > subEndDate &&
      Date.now() < subEndDate + TEN_DAYS_IN_MILLISECONDS
    : false

  const needsRenewal =
    ((isPaidPremium && isAboutToExpire) ||
      (!isPaidPremium && isSubscriptionExpired)) &&
    !['enterprise', 'partial_premium'].includes(subType)

  const showPopup =
    (needsRenewal || !isPaidPremium) &&
    !['enterprise', 'partial_premium'].includes(subType) &&
    !loading

  const sortableClasses = classData
    .filter((d) => d.asgnStartDate !== null && d.asgnStartDate !== undefined)
    .sort((a, b) => b.asgnStartDate - a.asgnStartDate)
  const unSortableClasses = classData.filter(
    (d) => d.asgnStartDate === null || d.asgnStartDate === undefined
  )

  const allClasses = [...sortableClasses, ...unSortableClasses]
  const allActiveClasses = allClasses.filter(
    (c) => c.active === 1 && c.type === 'class'
  )

  const closeCleverSyncModal = () => setShowCleverSyncModal(false)
  const closeCanvasSyncModal = () => setShowCanvasSyncModal(false)

  const handleShowTrialModal = () => setShowHeaderTrialModal(true)

  const isSignupComplete = currentSignUpState === signUpState.DONE

  const atleastOneClassPresent = allActiveClasses.length > 0

  const isHangoutEnabled =
    districtPolicy?.enableGoogleMeet === true
      ? true
      : schoolPolicy?.[0]?.enableGoogleMeet === true

  const numberOfSelectedItems = selectedItems && selectedItems.length

  const headingWithToggle = (
    <FlexContainer
      flexDirection="column"
      data-joyride-id="dashboardToggle"
      width="min-content"
    >
      <span>
        {t('common.dashboard')}
        {!isGcpsDistrict ? (
          <TooltipStyled
            title="You're using the new dashboard. Toggle to switch back to the old dashboard if you prefer."
            $bgColor="white"
            $color="#000"
            $border="1px solid #D8D8D8"
            $fontSize="12px"
            $textAlign="left"
          >
            <IconInfoCircle
              height={16}
              width={16}
              marginLeft="5px"
              data-cy="dashboardInfoIcon"
            />
          </TooltipStyled>
        ) : null}
      </span>
      {!isGcpsDistrict ? <DashboardToggle /> : null}
    </FlexContainer>
  )

  return (
    <MainHeader Icon={IconClockDashboard} headingText={headingWithToggle}>
      <SearchBox />
      <FlexContainer alignItems="center">
        {atleastOneClassPresent && isSignupComplete && isHangoutEnabled && (
          <Tooltip title="Launch Google Meet">
            <StyledEduButton
              isBlue
              data-cy="launch-google-meet"
              onClick={launchHangout}
              isGhost
            >
              <IconHangouts color={themeColor} height={21} width={19} />
              Hangout
            </StyledEduButton>
          </Tooltip>
        )}

        <CanvasClassSelectModal
          visible={showCanvasSyncModal}
          onCancel={closeCanvasSyncModal}
          user={userInfo}
          getCanvasCourseListRequest={getCanvasCourseListRequest}
          getCanvasSectionListRequest={getCanvasSectionListRequest}
          canvasCourseList={canvasCourseList}
          canvasSectionList={canvasSectionList}
          institutionId={institutionIds[0]}
        />
        <ClassSelectModal
          type="clever"
          visible={showCleverSyncModal}
          onSubmit={syncCleverClassList}
          onCancel={closeCleverSyncModal}
          loading={loadingCleverClassList}
          classListToSync={cleverClassList}
          courseList={courseList}
          getStandardsListBySubject={getStandardsListBySubject}
          refreshPage="dashboard"
          existingGroups={allClasses}
          defaultGrades={defaultGrades}
          defaultSubjects={defaultSubjects}
        />

        {numberOfSelectedItems ? (
          <EduButton
            isBlue
            isGhost
            onClick={() => {
              segmentApi.genericEventTrack('CreateTestWithxItems', {
                origin: 'dashboard',
                trigger: 'header',
                count_of_items: numberOfSelectedItems,
                role: userRole,
              })
              createTestFromCart()
            }}
            disabled={!numberOfSelectedItems}
            data-cy="dashboardCreateTestButton"
          >
            <IconShoppingBag />
            <span>Merge Test</span>
            <ItemsAmount threeDigit={numberOfSelectedItems > 99}>
              {numberOfSelectedItems}
            </ItemsAmount>
            items
          </EduButton>
        ) : (
          <CreateTestButton />
        )}

        {showPopup && !showDiscountOffer && (
          <PopoverWrapper>
            <Popover
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              trigger="click"
              placement="bottomRight"
              content={getContent({
                setvisible,
                needsRenewal,
                isPremiumUser,
                isPremiumTrialUsed,
                handleShowTrialModal,
                showDiscountOffer,
              })}
              onClick={() => setvisible(true)}
              visible={visible}
            >
              {needsRenewal ? (
                <EduButton
                  data-cy="renewSubscription"
                  type="primary"
                  isBlue
                  style={{
                    marginLeft: '5px',
                    backgroundColor: darkOrange1,
                    border: 'none',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    aria-hidden="true"
                  />
                  <span>RENEW SUBSCRIPTION</span>
                </EduButton>
              ) : (
                <EduButton
                  isBlue
                  style={{ marginLeft: '5px' }}
                  data-cy="tryPremiumForFree"
                  onClick={trackClick('dashboard:upgrade:click')}
                >
                  <i className="fa fa-unlock-alt" aria-hidden="true" />
                  {!isPremiumUser && !isPremiumTrialUsed
                    ? 'TRY PREMIUM'
                    : 'Upgrade'}
                </EduButton>
              )}
            </Popover>
          </PopoverWrapper>
        )}
        {showDiscountOffer && (
          <EduButton
            isBlue
            style={{
              marginLeft: '5px',
              backgroundColor: darkOrange1,
              border: 'none',
            }}
            data-cy="discountOffer"
            onClick={handelDiscountClick}
          >
            GRAB OFFER
          </EduButton>
        )}
      </FlexContainer>
    </MainHeader>
  )
}

NewHeaderSection.propTypes = {
  user: PropTypes.object.isRequired,
  isSubscriptionExpired: PropTypes.bool.isRequired,
  openLaunchHangout: PropTypes.func.isRequired,
}

const enhance = compose(
  withNamespaces('header'),
  connect(
    (state) => ({
      user: getUserSelector(state),
      subscription: state?.subscription?.subscriptionData?.subscription,
      isSubscriptionExpired: state?.subscription?.isSubscriptionExpired,
      districtPolicy: get(state, 'user.user.orgData.policies.district'),
      schoolPolicy: get(state, 'user.user.orgData.policies.institutions'),
      canvasCourseList: get(state, 'manageClass.canvasCourseList', []),
      canvasSectionList: get(state, 'manageClass.canvasSectionList', []),
      courseList: get(state, 'coursesReducer.searchResult'),
      loadingCleverClassList: get(state, 'manageClass.loadingCleverClassList'),
      cleverClassList: getCleverClassListSelector(state),
      showCleverSyncModal: get(state, 'manageClass.showCleverSyncModal', false),
      showCanvasSyncModal: get(state, 'manageClass.showCanvasSyncModal', false),
      classData: state.dashboardTeacher.data,
      institutionIds: currentDistrictInstitutionIds(state),
      getStandardsListBySubject: (subject) =>
        getFormattedCurriculumsSelector(state, { subject }),
      defaultGrades: getInterestedGradesSelector(state),
      defaultSubjects: getInterestedSubjectsSelector(state),
      isPremiumTrialUsed:
        state.subscription?.subscriptionData?.isPremiumTrialUsed,
      selectedItems: getSelectedItemSelector(state),
      isGcpsDistrict: isGcpsDistrictSelector(state, true),
      userRole: getUserRole(state),
      showDiscountOffer: getShowDiscountOfferSelector(state),
      loading: getSubscriptionLoading(state),
    }),
    {
      openLaunchHangout: launchHangoutOpen,
      setShowCleverSyncModal: setShowCleverSyncModalAction,
      syncCleverClassList: syncClassesWithCleverAction,
      getCanvasCourseListRequest: getCanvasCourseListRequestAction,
      getCanvasSectionListRequest: getCanvasSectionListRequestAction,
      setShowHeaderTrialModal: slice.actions.setShowHeaderTrialModal,
      setShowCanvasSyncModal: setShowCanvasSyncModalAction,
      createTestFromCart: createTestFromCartAction,
    }
  )
)

export default enhance(NewHeaderSection)

const StyledEduButton = styled(EduButton)`
  span {
    margin: 0 5px;
  }
  svg {
    .b {
      fill: ${white};
    }
  }
  &:hover,
  &:focus {
    .b {
      fill: ${themeColor};
    }
  }
`
