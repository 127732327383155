import {
  removeFromLocalStorage,
  storeInLocalStorage,
} from '@edulastic/api/src/utils/Storage'

import {
  FieldLabel,
  SelectInputStyled,
  EduButton,
  EduIf,
  notification,
} from '@edulastic/common'
import { Col, Row, Select } from 'antd'
import { get, pick as _pick, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { IconExpandBox, IconTrash } from '@edulastic/icons'
import { dictionaries } from '@edulastic/constants'
import { segmentApi } from '@edulastic/api'
import { red } from '@edulastic/colors'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'
import {
  getDefaultInterests,
  setDefaultInterests,
} from '../../../author/dataUtils'
import { updateDefaultCurriculumAction } from '../../../author/src/actions/dictionaries'
import {
  getAllFormattedCurriculumsSelector,
  getRecentStandardsListSelector,
} from '../../../author/src/selectors/dictionaries'
import {
  getAllInterestedCurriculumsSelector,
  getDefaultGradesSelector,
  getDefaultSubjectSelector,
  getInterestedGradesSelector,
} from '../../../author/src/selectors/user'
import selectsData from '../../../author/TestPage/components/common/selectsData'
import {
  updateDefaultGradesAction,
  updateDefaultSubjectAction,
} from '../../../student/Login/ducks'
import {
  alignmentStandardsFromUIToMongo,
  checkStandardsUpdated,
} from '../../utils/helpers'
import CustomTreeSelect from './CustomTreeSelect'
import RecentStandardsList from './RecentStandardsList'
import StandardsModal from './StandardsModal'
import { IconWrapper } from './styled/BrowseButton'
import { ItemBody } from './styled/ItemBody'
import { StyledDiv } from './styled/ELOList'
import { StyledRequired } from '../../../author/Reports/subPages/dataWarehouseReports/GoalsAndInterventions/common/components/Form/styled-components'
import { standardsFields } from '../../../author/AssessmentPage/VideoQuiz/constants'
import {
  getTestEntityGradesSelector,
  getTestEntitySubjectsSelector,
} from '../../../author/TestPage/ducks'
import {
  StyledSearchStandardCol,
  StyledStandardInputRow,
} from './styled/RowContainer'
import { setQuestionDataAction } from '../../../author/QuestionEditor/ducks'
import { getCurrentQuestionSelector } from '../../../author/sharedDucks/questions'

const AlignmentRow = ({
  t,
  curriculums,
  getCurriculumStandards,
  curriculumStandardsELO,
  alignment,
  alignmentIndex,
  qId,
  handleUpdateQuestionAlignment,
  curriculumStandardsLoading,
  editAlignment,
  createUniqGradeAndSubjects,
  formattedCuriculums,
  defaultGrades,
  interestedGrades,
  updateDefaultCurriculum,
  defaultSubject,
  defaultCurriculumId,
  updateDefaultGrades,
  updateDefaultSubject,
  interestedCurriculums,
  recentStandardsList = [],
  isDocBased = false,
  authorQuestionStatus = false,
  showIconBrowserBtn = false,
  hideLabel = false,
  standardsRequiredFields = [],
  testSelectedSubjects = [],
  testSelectedGrades = [],
  isEditView = false,
  isMetadataView = false,
  question = {},
  isQuestionsLoading,
  standardsFromRubric = [],
  disabled,
  isAIFlow = false,
  trackingEventSource,
  onDelete,
  numberOfAlignments = 0,
}) => {
  const {
    subject = 'Mathematics',
    curriculumId = 212,
    curriculum = 'Math - Common Core',
    grades = ['7'],
    standards = [],
  } = alignment

  const [oldStandards, setOldStandards] = useState([])

  let { shouldSkipEmptyStandardWarning = false } = question
  // allow selection of standards during ai flow
  shouldSkipEmptyStandardWarning = shouldSkipEmptyStandardWarning && !isAIFlow

  const userUpdate = useRef(authorQuestionStatus)

  // cleanup (on componentwillunmount)
  useEffect(
    () => () => {
      userUpdate.current = false
    },
    []
  )

  const [showModal, setShowModal] = useState(false)
  const setSubject = (val) => {
    userUpdate.current = true
    updateDefaultSubject(val)
    storeInLocalStorage('defaultSubject', val)
    removeFromLocalStorage('defaultCurriculumId')
    removeFromLocalStorage('defaultCurriculumName')
    updateDefaultCurriculum({
      defaultCurriculumId: '',
      defaultCurriculumName: '',
    })
    editAlignment(alignmentIndex, { subject: val, curriculum: '' })
    setDefaultInterests({ subject: [val] })
  }

  const setGrades = (val) => {
    userUpdate.current = true
    updateDefaultGrades(val)
    storeInLocalStorage('defaultGrades', val)
    editAlignment(alignmentIndex, { grades: val })
    setDefaultInterests({ grades: val })
  }

  const handleChangeStandard = (_curriculum, event) => {
    userUpdate.current = true
    const _curriculumId = parseInt(event.key, 10)
    storeInLocalStorage('defaultCurriculumId', _curriculumId)
    storeInLocalStorage('defaultCurriculumName', _curriculum)
    updateDefaultCurriculum({
      defaultCurriculumId: _curriculumId,
      defaultCurriculumName: _curriculum,
    })
    editAlignment(alignmentIndex, {
      curriculumId: _curriculumId,
      curriculum: _curriculum,
    })
    setDefaultInterests({ curriculumId: _curriculumId })
  }

  const standardsArr = standards.map((el) => el.identifier)

  const handleSearchStandard = (searchStr) => {
    getCurriculumStandards({ id: curriculumId, grades, searchStr })
  }

  const handleAddStandard = (newStandard) => {
    userUpdate.current = true

    let newStandards = standards.some(
      (standard) => standard._id === newStandard._id
    )
    if (newStandards) {
      newStandards = standards.filter(
        (standard) => standard._id !== newStandard._id
      )
    } else {
      newStandards = [...standards, newStandard]
    }
    const standardsGrades = newStandards.flatMap((standard) => standard.grades)
    createUniqGradeAndSubjects([...grades, ...standardsGrades], subject)

    editAlignment(alignmentIndex, {
      standards: newStandards,
    })

    segmentApi.genericEventTrack('AddStandards', {
      source:
        trackingEventSource ||
        (isEditView
          ? 'EditView'
          : isMetadataView
          ? 'MetaDataTabView'
          : 'Other'),
    })
  }

  const handleStandardSelect = (chosenStandardsArr, option) => {
    userUpdate.current = true
    const newStandard = _pick(option.props.obj, [
      '_id',
      'level',
      'grades',
      'identifier',
      'tloIdentifier',
      'tloId',
      'tloDescription',
      'eloId',
      'subEloId',
      'description',
      'curriculumId',
    ])

    handleAddStandard(newStandard)
  }

  const handleStandardDeselect = (removedElement) => {
    const newStandards = standards.filter((el) => {
      const itemToBeRemoved = el.identifier === removedElement
      if (itemToBeRemoved) {
        if (
          standardsFromRubric?.length &&
          standardsFromRubric.includes(el._id)
        ) {
          notification({
            type: 'info',
            msg:
              'This standard is linked to rubric criterion. To delete it, please remove it from the associated criterion.',
          })
          return true
        }
        return false
      }
      return true
    })
    standards.length !== newStandards.length &&
      editAlignment(alignmentIndex, { standards: newStandards })
  }

  const handleApply = (data) => {
    if (
      standardsFromRubric.length &&
      standardsFromRubric.some(
        (std) => !data.eloStandards.find((eloStd) => eloStd._id === std)
      )
    ) {
      notification({
        type: 'info',
        msg:
          'This standard is linked to rubric criterion. To delete it, please remove it from the associated criterion.',
      })
      return
    }
    const gradesFromElo = data.eloStandards.flatMap((elo) => elo.grades)
    let { subject: _subject } = data
    if (!_subject) {
      const curriculumFromStandard = data.standard.id
        ? formattedCuriculums.find((c) => c.value === data.standard.id) || {}
        : {}
      _subject = curriculumFromStandard?.subject
    }
    createUniqGradeAndSubjects([...data.grades, ...gradesFromElo], subject)
    editAlignment(alignmentIndex, {
      subject: data.subject,
      curriculum: data.standard.curriculum,
      curriculumId: data.standard.id,
      grades: data.grades,
      standards: data.eloStandards,
    })

    setShowModal(false)
  }

  const handleStandardFocus = () => {
    getCurriculumStandards({ id: curriculumId, grades, searchStr: '' })
  }

  const handleShowBrowseModal = () => {
    if (!disabled) {
      setShowModal(true)
      segmentApi.genericEventTrack('BrowseStandards', {
        source: isEditView
          ? 'EditView'
          : isMetadataView
          ? 'MetaDataTabView'
          : 'Other',
      })
    }
  }

  useEffect(() => {
    const isStandardsUpdated = checkStandardsUpdated(oldStandards, standards)
    if (!isQuestionsLoading && isStandardsUpdated) {
      // Do not update alignment until the question is fully loaded
      handleUpdateQuestionAlignment(
        alignmentIndex,
        {
          curriculum,
          curriculumId,
          subject,
          grades,
          domains: alignmentStandardsFromUIToMongo([...standards]),
        },
        userUpdate.current
      )
      setOldStandards(standards)
    }
  }, [alignment])

  useEffect(() => {
    /**
     * This code is also used in /GradingRubric/Components/StandardsBox.js
     * any logic update need to be handled in both places.
     * Due to the impact areas this code is not extracted to a common function
     * */
    const { curriculumId: alCurriculumId } = alignment
    // TODO use getPreviouslyUsedOrDefaultInterestsSelector from src/client/author/src/selectors/user.js
    const defaultInterests = getDefaultInterests()
    /**
     * [OLD] EV-16395: The test subjects field data was migrated and fixed. Thus updating below line to get subjects
     *
     *
     * The subject in session storage is saved as an array of subjects or a subject string.
     * To handle this and to support backward compatibility for current users where session
     * storage may contains a subject string, we check if the subject is an array and
     * retrieve the value accordingly. For the fix, we will follow the plan below to
     * fix this permanently.
     *
     * Plan:
     *
     * 1. Handle both subject arrays and subject strings. [DONE]
     * 2. Update all places where subject occurs to use an string. Search for occurrences of setDefaultInterests. [DONE]
     * 3. Remove the array check from below.
     */

    /**
     * When subject is available in test and it does not with previously selected curriculum's subject we use empty string
     * Else we use previously selected curriculum's name
     * */
    const getCurriculumDetails = (
      curriculumIdentifier,
      testSubject,
      testGrades
    ) => {
      const _curriculum =
        curriculums.find(
          (item) => item._id === parseInt(curriculumIdentifier, 10)
        ) || {}
      const _curriculumName =
        !isEmpty(testSubject) && testSubject !== _curriculum.subject
          ? ''
          : _curriculum.curriculum || ''

      const _curriculumId = _curriculumName
        ? parseInt(curriculumIdentifier, 10) || ''
        : ''

      const _alignmentSubject = _curriculumName
        ? _curriculum.subject || ''
        : testSubject || ''

      const _alignmentGrades = (_curriculum?.grades || []).filter((grade) =>
        (testGrades || []).includes(grade)
      )

      return {
        _curriculumName,
        _curriculumId,
        _alignmentGrades: _alignmentGrades.length
          ? _alignmentGrades
          : testGrades,
        _alignmentSubject,
      }
    }

    let _subject = defaultInterests?.subject
    _subject = (Array.isArray(_subject) ? _subject[0] : _subject) || ''

    const testSubject = testSelectedSubjects?.length
      ? testSelectedSubjects[0]
      : ''
    const testGrades = testSelectedGrades?.length ? testSelectedGrades : null

    if (!alCurriculumId && alignmentIndex === 0) {
      if (
        _subject ||
        defaultInterests.grades?.length ||
        defaultInterests.curriculumId
      ) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(
          defaultInterests.curriculumId,
          testSubject,
          testGrades
        )

        editAlignment(alignmentIndex, {
          subject: _alignmentSubject || _subject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades:
            _alignmentGrades || defaultInterests.grades?.length
              ? defaultInterests.grades
              : [],
        })
      } else if (defaultSubject && defaultCurriculumId) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(defaultCurriculumId, testSubject, testGrades)
        editAlignment(alignmentIndex, {
          subject: _alignmentSubject || defaultSubject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades: _alignmentGrades || defaultGrades || interestedGrades || [],
        })
      } else if (interestedCurriculums && interestedCurriculums.length > 0) {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(
          interestedCurriculums[0]._id,
          testSubject,
          testGrades
        )

        editAlignment(alignmentIndex, {
          subject: _alignmentSubject || interestedCurriculums[0].subject,
          curriculum: _curriculumName,
          curriculumId: _curriculumId,
          grades: _alignmentGrades || defaultGrades || interestedGrades || [],
        })
      } else {
        const {
          _curriculumName,
          _curriculumId,
          _alignmentSubject,
          _alignmentGrades,
        } = getCurriculumDetails(212, testSubject, testGrades)

        editAlignment(alignmentIndex, {
          subject: _alignmentSubject || 'Mathematics',
          curriculumId: _curriculumId,
          curriculum: _curriculumName,
          grades: _alignmentGrades || ['7'],
          standards: [],
        })
      }
    }
  }, [qId, curriculums])
  const showMoreButtonEnabled =
    !curriculumStandardsLoading &&
    curriculumStandardsELO &&
    curriculumStandardsELO.length >= dictionaries.STANDARD_DROPDOWN_LIMIT_1000

  const showStandardsRequiredLabel = standardsRequiredFields?.length > 0

  const showTrashIcon = alignmentIndex > 0 && (isMetadataView || isEditView)
  return (
    <>
      {showModal && (
        <StandardsModal
          t={t}
          defaultSubject={subject}
          defaultGrades={grades}
          defaultStandards={standards}
          defaultStandard={{ curriculum, id: curriculumId }}
          visible={showModal}
          curriculums={curriculums}
          onApply={handleApply}
          setSubject={setSubject}
          onCancel={() => setShowModal(false)}
          getCurriculumStandards={getCurriculumStandards}
          curriculumStandardsLoading={curriculumStandardsLoading}
          editAlignment={editAlignment}
          alignmentIndex={alignmentIndex}
          showAllInterestedCurriculums
        />
      )}
      <Row style={{ paddingBottom: '20px' }}>
        <EduIf condition={!hideLabel}>
          <FieldLabel>
            {`Standards ${showStandardsRequiredLabel ? `` : `(optional)`}`}
            <EduIf condition={showStandardsRequiredLabel}>
              <StyledRequired>*</StyledRequired>
            </EduIf>
          </FieldLabel>
        </EduIf>
        <EduIf condition={!shouldSkipEmptyStandardWarning || !isEditView}>
          <>
            <StyledStandardInputRow isEditView={isEditView} gutter={24}>
              <Col md={showIconBrowserBtn ? 12 : 10}>
                <CustomTreeSelect
                  disabled={disabled}
                  bg={!showIconBrowserBtn && 'white'}
                  data-cy="subjectStandardSet"
                  title={`${curriculum}${
                    curriculum && grades.length ? ' - ' : ''
                  }${grades.length ? 'Grade - ' : ''}${
                    grades.length ? grades : ''
                  }`}
                  shouldSkipEmptyStandardWarning={
                    shouldSkipEmptyStandardWarning
                  }
                  dropDownIconType={isAIFlow ? 'down' : undefined}
                  dropDownIconFontSizeThemeValue={
                    isAIFlow ? 'standardFont' : undefined
                  }
                >
                  <>
                    <ItemBody data-cy="subjectItem">
                      <FieldLabel>
                        {t('component.options.subject')}
                        <EduIf
                          condition={standardsRequiredFields.includes(
                            standardsFields.SUBJECT
                          )}
                        >
                          <StyledRequired>*</StyledRequired>
                        </EduIf>
                      </FieldLabel>
                      <SelectInputStyled
                        data-cy="subjectSelect"
                        value={subject}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={setSubject}
                        disabled={shouldSkipEmptyStandardWarning}
                      >
                        {selectsData.allSubjects.map(({ text, value }) =>
                          value ? (
                            <Select.Option key={value} value={value}>
                              {text}
                            </Select.Option>
                          ) : (
                            ''
                          )
                        )}
                      </SelectInputStyled>
                    </ItemBody>
                    <ItemBody data-cy="standardItem">
                      <FieldLabel>
                        {t('component.options.standardSet')}
                        <EduIf
                          condition={standardsRequiredFields.includes(
                            standardsFields.STANDARD_SET
                          )}
                        >
                          <StyledRequired>*</StyledRequired>
                        </EduIf>
                      </FieldLabel>
                      <SelectInputStyled
                        data-cy="standardSetSelect"
                        showSearch
                        filterOption
                        value={curriculum}
                        dropdownClassName="custom-antd-select"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={handleChangeStandard}
                        disabled={shouldSkipEmptyStandardWarning}
                      >
                        {formattedCuriculums.map(
                          ({ value, text, disabled: disabledOption }) => (
                            <Select.Option
                              key={value}
                              value={text}
                              disabled={disabledOption}
                            >
                              {text}
                            </Select.Option>
                          )
                        )}
                      </SelectInputStyled>
                    </ItemBody>
                    <ItemBody data-cy="gradeItem">
                      <FieldLabel>
                        {t('component.options.grade')}
                        <EduIf
                          condition={standardsRequiredFields.includes(
                            standardsFields.GRADES
                          )}
                        >
                          <StyledRequired>*</StyledRequired>
                        </EduIf>
                      </FieldLabel>
                      <SelectInputStyled
                        data-cy="gradeSelect"
                        mode="multiple"
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        value={grades}
                        onChange={setGrades}
                        disabled={shouldSkipEmptyStandardWarning}
                      >
                        {selectsData.allGrades.map(({ text, value }) => (
                          <Select.Option key={text} value={value}>
                            {text}
                          </Select.Option>
                        ))}
                      </SelectInputStyled>
                    </ItemBody>
                  </>
                </CustomTreeSelect>
              </Col>
              <StyledSearchStandardCol
                md={showIconBrowserBtn ? 12 : 10}
                paddingRight="0px !important"
              >
                <div data-cy="searchStandardSelectItem">
                  <SelectInputStyled
                    bg={!showIconBrowserBtn && 'white'}
                    data-cy="searchStandardSelect"
                    mode="multiple"
                    style={{ margin: 'auto', display: 'block' }}
                    placeholder={t('component.options.searchStandards')}
                    filterOption={false}
                    value={standardsArr}
                    optionLabelProp="title"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onFocus={handleStandardFocus}
                    onSearch={handleSearchStandard}
                    onSelect={handleStandardSelect}
                    onDeselect={handleStandardDeselect}
                    disabled={shouldSkipEmptyStandardWarning || disabled}
                  >
                    {!curriculumStandardsLoading &&
                      curriculumStandardsELO &&
                      curriculumStandardsELO.length > 0 &&
                      curriculumStandardsELO.map((el) => (
                        <Select.Option
                          title={el.identifier}
                          key={el._id}
                          value={el.identifier}
                          obj={el}
                          style={{ whiteSpace: 'normal' }}
                        >
                          <div>
                            <div>
                              <b>{el.identifier}</b>
                            </div>
                            <div
                              className="selected-item-desctiption"
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(el.description),
                              }}
                            />
                          </div>
                        </Select.Option>
                      ))}
                    {showMoreButtonEnabled && (
                      <Select.Option
                        title="Show More"
                        value="show"
                        style={{ display: 'block', cursor: 'pointer' }}
                        disabled
                      >
                        <StyledDiv onClick={handleShowBrowseModal}>
                          <span>Show More</span>
                        </StyledDiv>
                      </Select.Option>
                    )}
                  </SelectInputStyled>
                </div>

                {numberOfAlignments < 2 &&
                  recentStandardsList &&
                  recentStandardsList.length > 0 &&
                  !isDocBased &&
                  !isAIFlow && (
                    <RecentStandardsList
                      recentStandardsList={recentStandardsList}
                      standardsArr={standardsArr}
                      handleAddStandard={handleAddStandard}
                      shouldSkipEmptyStandardWarning={
                        shouldSkipEmptyStandardWarning
                      }
                    />
                  )}
              </StyledSearchStandardCol>

              {showIconBrowserBtn ? (
                <IconWrapper disabled={disabled} data-cy="standardBrowseButton">
                  <IconExpandBox onClick={handleShowBrowseModal} />
                </IconWrapper>
              ) : (
                <Col md={4}>
                  <Row gutter={[24]} type="flex" align="middle">
                    <Col xs={22}>
                      <EduButton
                        width="100%"
                        height="35px"
                        isGhost
                        ml="10px"
                        onClick={handleShowBrowseModal}
                        disabled={shouldSkipEmptyStandardWarning}
                      >
                        {t('component.options.browse')}
                      </EduButton>
                    </Col>
                    <Col xs={2}>
                      <EduIf condition={showTrashIcon}>
                        <IconTrash
                          color={red}
                          onClick={onDelete(curriculumId)}
                        />
                      </EduIf>
                    </Col>
                  </Row>
                </Col>
              )}
            </StyledStandardInputRow>

            {(alignment || []).length < 2 &&
              recentStandardsList &&
              recentStandardsList.length > 0 &&
              isDocBased &&
              !isAIFlow && (
                <Col xs={24}>
                  <RecentStandardsList
                    isDocBased
                    recentStandardsList={recentStandardsList}
                    standardsArr={standardsArr}
                    handleAddStandard={handleAddStandard}
                    shouldSkipEmptyStandardWarning={
                      shouldSkipEmptyStandardWarning
                    }
                  />
                </Col>
              )}
          </>
        </EduIf>
      </Row>
    </>
  )
}

AlignmentRow.propTypes = {
  t: PropTypes.func.isRequired,
  getCurriculumStandards: PropTypes.func.isRequired,
  curriculums: PropTypes.array.isRequired,
  curriculumStandardsELO: PropTypes.array.isRequired,
  alignment: PropTypes.object.isRequired,
  editAlignment: PropTypes.func.isRequired,
  standardsRequiredFields: PropTypes.array,
  testSelectedSubjects: PropTypes.array,
  testSelectedGrades: PropTypes.array,
  numberOfAlignments: PropTypes.number,
}

AlignmentRow.defaultProps = {
  standardsRequiredFields: [],
  testSelectedSubjects: [],
  testSelectedGrades: [],
  numberOfAlignments: 1,
}

export default connect(
  (state, props) => ({
    defaultCurriculumId: get(state, 'dictionaries.defaultCurriculumId'),
    defaultCurriculumName: get(state, 'dictionaries.defaultCurriculumName'),
    formattedCuriculums: getAllFormattedCurriculumsSelector(
      state,
      props.alignment
    ),
    defaultGrades: getDefaultGradesSelector(state),
    interestedGrades: getInterestedGradesSelector(state),
    defaultSubject: getDefaultSubjectSelector(state),
    recentStandardsList: getRecentStandardsListSelector(state),
    testSelectedSubjects: getTestEntitySubjectsSelector(state),
    testSelectedGrades: getTestEntityGradesSelector(state),
    interestedCurriculums: getAllInterestedCurriculumsSelector(state),
    question: getCurrentQuestionSelector(state),
    isQuestionsLoading: get(state, 'itemDetail.isQuestionsLoading', false),
  }),
  {
    updateDefaultCurriculum: updateDefaultCurriculumAction,
    updateDefaultSubject: updateDefaultSubjectAction,
    updateDefaultGrades: updateDefaultGradesAction,
    setQuestionData: setQuestionDataAction,
  }
)(AlignmentRow)
