import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { segmentApi } from '@edulastic/api'

import {
  EduSwitchStyled,
  FlexContainer,
  EduButton,
  CustomModalStyled,
  TextAreaInputStyled,
  EduJoyride,
} from '@edulastic/common'

import { tutorials } from '@edulastic/constants'
import { ACTIONS, EVENTS, LIFECYCLE } from 'react-joyride'
import {
  getUserId,
  getUserOrgId,
  isPremiumUserSelector,
  isVideoQuizAndAIEnabledSelector,
} from '../../../src/selectors/user'
import {
  getDashboardSettingsSelector,
  setDashboardSettingsAction,
  getTotalAssignmentCount,
  isTeacherDashboardLoading,
  getDashboardJoyrideVisbile,
} from '../../ducks'
import { FILTER_TYPES } from '../DashboardSubHeader/utils'
import {
  setViewedTutorialsAction,
  viewedTutorialsSelector,
} from '../../../../tutorials/tutorialReducer'
import {
  DASHBOARD_VERSION,
  dashboardToggleSteps,
  newDashboardOverviewSteps,
  newDashboardOverviewStepsWithoutLifecycleBanner,
} from '../../constants'

const DashboardToggle = ({
  dashboardSettings: { dashboardVersion, feedbackProvided, preselectedUser },
  setDashboardSettings,
  userId,
  districtId,
  viewedTutorials,
  setViewedTutorials,
  totalAssignmentCount,
  isLoading,
  showJoyride,
}) => {
  const [feedbackPopupVisible, setFeedbackPopupVisible] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [joyrideRun, setJoyrideRun] = useState(true)
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0)
  const [joyrideClosed, setJoyrideClosed] = useState(false)

  const handleToggleSegmentEvent = (currentToggleState) => {
    segmentApi.genericEventTrack('DashboardVisitedToggleStatus', {
      beta: preselectedUser ? 'yes' : 'no',
      toggleVisible: 'yes',
      currentToggleState,
    })
    setFeedbackPopupVisible(false)
  }

  const handleFeedbackSegmentEvent = () => {
    segmentApi.genericEventTrack('DashboardFeedbackSubmitted', {
      dashboardFeedback: feedback,
    })
    setFeedbackPopupVisible(false)
  }

  const toggleDashboardVersion = () => {
    // *******  setting filter to all classes in dashboard start ******
    localStorage.setItem(
      `author:dashboard:classFilter:${userId}:${districtId}`,
      FILTER_TYPES.ALL_CLASSES
    )
    // ******* End ******
    if (feedbackProvided || !dashboardVersion) {
      setDashboardSettings({
        dashboardVersion: dashboardVersion
          ? DASHBOARD_VERSION.OLD_DASHBOARD
          : DASHBOARD_VERSION.NEW_DASHBOARD,
        feedbackProvided,
        preselectedUser,
      })
      handleToggleSegmentEvent(dashboardVersion ? 'old' : 'new')
    } else {
      setFeedbackPopupVisible(true)
    }
  }

  const handleSkip = () => {
    setDashboardSettings({
      dashboardVersion: DASHBOARD_VERSION.OLD_DASHBOARD,
      feedbackProvided: false,
      preselectedUser,
    })
    handleToggleSegmentEvent('old')
  }

  const handleSubmit = () => {
    setDashboardSettings({
      dashboardVersion: DASHBOARD_VERSION.OLD_DASHBOARD,
      feedbackProvided: true,
      preselectedUser,
    })
    handleToggleSegmentEvent('old')
    handleFeedbackSegmentEvent()
  }

  const hideLifecycleStep = isLoading || totalAssignmentCount >= 2

  const joyrideSteps = dashboardVersion
    ? hideLifecycleStep
      ? newDashboardOverviewStepsWithoutLifecycleBanner
      : newDashboardOverviewSteps
    : dashboardToggleSteps

  const joyrideCallback = (data) => {
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
      const nextStepIndex = data.index + (data.action === ACTIONS.PREV ? -1 : 1)
      const element = document.querySelector(
        joyrideSteps[nextStepIndex]?.target
      )
      setJoyrideRun(false)

      element?.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      })
      if (data.action === ACTIONS.CLOSE) {
        setJoyrideClosed(true)
      }
      if (data.action !== ACTIONS.CLOSE && !joyrideClosed) {
        setTimeout(() => {
          setJoyrideStepIndex(nextStepIndex)
          setJoyrideRun(true)
        }, 400)
      }
    }
    if (dashboardVersion && !joyrideClosed) {
      const stepsSkipped = hideLifecycleStep ? 1 : 0
      const isClassTabStep = data.index === 2 - stepsSkipped
      const classViewChangeStep = data.index === 3 - stepsSkipped
      if (
        data.action === ACTIONS.UPDATE &&
        (isClassTabStep || classViewChangeStep)
      ) {
        const tabElement = document.querySelector(
          '[data-joyride-id="classesTab"]'
        )
        tabElement?.click()
      }
    }
    if (
      data.action === ACTIONS.CLOSE ||
      (data.lifecycle === LIFECYCLE.COMPLETE &&
        data.action === ACTIONS.NEXT &&
        data.index === joyrideSteps.length - 1)
    ) {
      setJoyrideRun(false)
      setViewedTutorials([
        ...viewedTutorials,
        dashboardVersion
          ? tutorials.newDashboardOverviewSteps
          : tutorials.dashboardToggle,
      ])
    }
  }

  return (
    <FlexContainer alignItems="flex-end" justifyContent="flex-start">
      <ToggleLabel>See New Version</ToggleLabel>
      <EduSwitchStyled
        checked={!!dashboardVersion}
        onChange={toggleDashboardVersion}
        data-cy="dashboardSwitchToggleButton"
        aria-label="Toggle Dashboard Version"
      />
      <StyledModal
        visible={feedbackPopupVisible}
        title="Sorry to see you want to use the older dashboard version."
        onCancel={() => setFeedbackPopupVisible(false)}
        maskClosable={false}
        modalWidth="720px"
        centered
        footer={[
          <EduButton
            height="40px"
            isGhost
            key="skipButton"
            onClick={handleSkip}
            data-cy="feedbackSkipButton"
          >
            SKIP & PROCEED
          </EduButton>,
          <EduButton
            height="40px"
            key="submitButton"
            onClick={handleSubmit}
            disabled={!feedback || feedback.length > 500}
            data-cy="feedbackSubmitButton"
          >
            SUBMIT FEEDBACK
          </EduButton>,
        ]}
      >
        <p>Please share your reasons so we can improve</p>
        <TextAreaInputStyled
          align="center"
          value={feedback}
          onChange={(event) => setFeedback(event.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{ color: 'black', fontWeight: 500 }}
          data-cy="feedbackInput"
        />
        {feedback.length > 500 && (
          <ErrorMessage data-cy="limitExceededMessage">
            You have exceeded the limit of 500 characters
          </ErrorMessage>
        )}
      </StyledModal>
      {showJoyride ? (
        <EduJoyride
          steps={joyrideSteps}
          locale={{
            close: 'Done',
            last: 'Done',
          }}
          disableOverlayClose
          showProgress={joyrideSteps.length > 1}
          options={{ zIndex: 1015 }}
          callback={joyrideCallback}
          continuous
          disableScrolling
          stepIndex={joyrideStepIndex}
          run={joyrideRun}
          showSkipButton
        />
      ) : null}
    </FlexContainer>
  )
}

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      isPremiumsUser: isPremiumUserSelector(state),
      isVideoQuizAndAIEnabled: isVideoQuizAndAIEnabledSelector(state),
      dashboardSettings: getDashboardSettingsSelector(state),
      userId: getUserId(state),
      districtId: getUserOrgId(state),
      viewedTutorials: viewedTutorialsSelector(state),
      totalAssignmentCount: getTotalAssignmentCount(state),
      isLoading: isTeacherDashboardLoading(state),
      showJoyride: getDashboardJoyrideVisbile(state),
    }),
    {
      setDashboardSettings: setDashboardSettingsAction,
      setViewedTutorials: setViewedTutorialsAction,
    }
  )
)

export default enhance(DashboardToggle)

const ToggleLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
  margin-top: 5px;
`

const ErrorMessage = styled.div`
  color: red;
  text-align: start;
  padding-top: 5px;
`

const StyledModal = styled(CustomModalStyled)`
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-size: 20px;
      }
    }

    .ant-modal-body {
      padding: 40px 0;
      p {
        font-size: 16px;
      }
    }
  }
`
