import React from 'react'
import { formatDate } from '@edulastic/constants/reportUtils/common'
import { MdCheck, MdClose, MdTimelapse } from 'react-icons/md'

import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { GiSandsOfTime } from 'react-icons/gi'
import { FiDownload } from 'react-icons/fi'

import { EduIf, EduThen, EduElse, FlexContainer } from '@edulastic/common'
import { lightGrey11, accessibilityColors } from '@edulastic/colors'

import { FEED_NAME_LABEL } from '@edulastic/constants/const/dataWarehouse'
import { sanitizeUrl } from '@edulastic/common/src/utils/html'
import { getFailedStatusTooltipText, sortByDate, sortText } from './helpers'
import TableActionIcon from '../components/TableActionIcon'
import {
  StyledSpan,
  StyledTag,
  StyledTooltip,
  StyledIconUploadFile,
} from '../../common/components/StyledComponents'

export const dwLogStatus = {
  IN_PROGRESS: 0,
  SUCCESS: 1,
  FAILED: 2,
  PARTIALLY_FAILED: 3,
  ARCHIVED: 4,
}

export const DATA_WAREHOUSE_MODAL_MODES = {
  UPLOAD: 'upload',
  EDIT: 'edit',
  DELETE: 'delete',
}

const uploadStatusMap = {
  [dwLogStatus.IN_PROGRESS]: () => (
    <StyledTag
      $color={accessibilityColors.labels.grey.text}
      $bgColor={accessibilityColors.labels.grey.bg}
    >
      <GiSandsOfTime /> <span>Processing</span>
    </StyledTag>
  ),
  [dwLogStatus.SUCCESS]: () => (
    <StyledTag
      $color={accessibilityColors.labels.green.text}
      $bgColor={accessibilityColors.labels.green.bg}
    >
      <MdCheck /> <span>Successful</span>
    </StyledTag>
  ),
  [dwLogStatus.FAILED]: ({ statusReason }) => {
    const tooltipText = getFailedStatusTooltipText(statusReason)
    return (
      <StyledTooltip
        title={statusReason && <StyledSpan>{tooltipText}</StyledSpan>}
        placement="right"
      >
        <StyledTag
          $color={accessibilityColors.labels.red.text}
          $bgColor={accessibilityColors.labels.red.bg}
        >
          <MdClose /> <span>Failed</span>
        </StyledTag>
      </StyledTooltip>
    )
  },
  [dwLogStatus.PARTIALLY_FAILED]: ({ failedCount }) => {
    const [textRowHas, textError] =
      failedCount > 1 ? ['rows have', 'errors'] : ['row has', 'error']
    const tooltipText = (
      <>
        {failedCount} {textRowHas} {textError}, please fix the {textError} and{' '}
        <StyledIconUploadFile /> Re-Upload the entire file.
      </>
    )
    return (
      <StyledTooltip
        title={<StyledSpan>{tooltipText}</StyledSpan>}
        placement="right"
      >
        <StyledTag
          $color={accessibilityColors.labels.yellow.text}
          $bgColor={accessibilityColors.labels.yellow.bg}
        >
          <MdTimelapse /> <span>Partially Successful</span>
        </StyledTag>
      </StyledTooltip>
    )
  },
  [dwLogStatus.ARCHIVED]: <StyledTag>Archived</StyledTag>,
}

export const COLUMN_KEYS = {
  FEED_TYPE: 'feedType',
  FEED_NAME: 'feedName',
  SCHOOL_TERM: 'termId',
  LAST_UPDATED: 'updatedAt',
  ADDED: 'addedCount',
  FAILED: 'failedCount',
  STATUS: 'status',
  DOWNLOAD_ERRORS: 'errorsFileDownloadLink',
  EDIT: 'edit',
  ACTIONS: 'actions',
}

export const tableColumns = [
  {
    title: 'Feed Type',
    dataIndex: COLUMN_KEYS.FEED_TYPE,
    key: COLUMN_KEYS.FEED_TYPE,
    sorter: (a, b) => sortText(a, b, COLUMN_KEYS.FEED_TYPE),
  },
  {
    title: FEED_NAME_LABEL,
    dataIndex: COLUMN_KEYS.FEED_NAME,
    key: COLUMN_KEYS.FEED_NAME,
    render: (value = '') => (
      <StyledTooltip title={<StyledSpan>{value}</StyledSpan>} placement="right">
        <div className="test-name">{value}</div>
      </StyledTooltip>
    ),
    sorter: (a, b) => sortText(a, b, COLUMN_KEYS.FEED_NAME),
  },
  {
    title: 'School Term',
    dataIndex: COLUMN_KEYS.SCHOOL_TERM,
    key: COLUMN_KEYS.SCHOOL_TERM,
  },
  {
    title: 'Last Updated',
    dataIndex: COLUMN_KEYS.LAST_UPDATED,
    key: COLUMN_KEYS.LAST_UPDATED,
    render: (value) => formatDate(value),
    sorter: (a, b) =>
      sortByDate(a[COLUMN_KEYS.LAST_UPDATED], b[COLUMN_KEYS.LAST_UPDATED]),
  },
  {
    title: 'Added Records',
    dataIndex: COLUMN_KEYS.ADDED,
    key: COLUMN_KEYS.ADDED,
    align: 'center',
    render: (value, record) =>
      record.status === dwLogStatus.IN_PROGRESS ? '-' : value,
    sorter: (a, b) => {
      const sortKey = COLUMN_KEYS.ADDED
      return sortByDate(a[sortKey], b[sortKey])
    },
  },
  {
    title: 'Failed',
    dataIndex: COLUMN_KEYS.FAILED,
    key: COLUMN_KEYS.FAILED,
    align: 'center',
    render: (value = '-') => <div>{value}</div>,
    sorter: (a, b) => a[COLUMN_KEYS.FAILED] - b[COLUMN_KEYS.FAILED],
  },
  {
    title: 'Status',
    dataIndex: COLUMN_KEYS.STATUS,
    key: COLUMN_KEYS.STATUS,
    render: (value, record) => {
      const StatusComponent = uploadStatusMap[value]
      return (
        <StatusComponent
          statusReason={record.statusReason}
          failedCount={record.failedCount}
        />
      )
    },
    sorter: (a, b) => a[COLUMN_KEYS.STATUS] - b[COLUMN_KEYS.STATUS],
  },
  {
    title: (
      <FlexContainer alignItems="center">
        Check And Fix Issues&nbsp;&nbsp;
        <StyledTooltip
          title={
            <StyledSpan
              data-cy="checkAndFixTooltipMessageText"
              $textTransform="initial"
            >
              Download the file to review warnings and errors. Correct the data
              as needed and re-upload the entire file to update the records.
            </StyledSpan>
          }
          placement="bottom"
        >
          <BsFillQuestionCircleFill
            data-cy="checkAndFixTooltipIcon"
            size="13px"
            color={lightGrey11}
          />
        </StyledTooltip>
      </FlexContainer>
    ),
    dataIndex: COLUMN_KEYS.DOWNLOAD_ERRORS,
    key: COLUMN_KEYS.DOWNLOAD_ERRORS,
    render: (value, record) => (
      <EduIf condition={record.failedCount && value}>
        <EduThen>
          <a
            href={sanitizeUrl(value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableActionIcon
              icon={<FiDownload size="16px" />}
              label="View Errors/Warnings"
            />
          </a>
        </EduThen>
        <EduElse>
          <div>-</div>
        </EduElse>
      </EduIf>
    ),
  },
  {
    title: (
      <FlexContainer alignItems="center">
        Modify Data&nbsp;&nbsp;
        <StyledTooltip
          title={
            <StyledSpan
              data-cy="modifyDataTooltipMessageText"
              $textTransform="initial"
            >
              Correct any issues and re-upload the complete, corrected file to
              replace the previously added records.
            </StyledSpan>
          }
          placement="bottom"
        >
          <BsFillQuestionCircleFill
            data-cy="modifyDataTooltipIcon"
            size="13px"
            color={lightGrey11}
          />
        </StyledTooltip>
      </FlexContainer>
    ),
    dataIndex: COLUMN_KEYS.EDIT,
    key: COLUMN_KEYS.EDIT,
  },
  {
    title: 'Actions',
    dataIndex: COLUMN_KEYS.ACTIONS,
    key: COLUMN_KEYS.ACTIONS,
  },
]
