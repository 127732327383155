import {
  AnswerContext,
  withWindowSizes,
  notification,
  EduButton,
  EduIf,
  EduThen,
  EduElse,
} from '@edulastic/common'
import { withNamespaces } from '@edulastic/localization'
import {
  get,
  isUndefined,
  maxBy,
  round,
  sumBy,
  toNumber,
  isNaN,
  isEmpty,
  isEqual,
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import * as Sentry from '@sentry/browser'
import UnScored from '@edulastic/common/src/components/Unscored'
import { LIKERT_SCALE } from '@edulastic/constants/const/questionType'
import { setTeacherEditedScore as setTeacherEditedScoreAction } from '../../author/ExpressGrader/ducks'
import { updateStudentQuestionActivityScoreAction } from '../../author/sharedDucks/classResponses'
import { hasValidAnswers } from '../utils/answer'
import { receiveFeedbackResponseAction } from '../../author/src/actions/classBoard'
import {
  getErrorResponse,
  getStatus,
} from '../../author/src/selectors/feedback'
import {
  getUserSelector,
  getUserThumbnail,
  getUserFullNameSelector,
} from '../../author/src/selectors/user'
import { getAvatarName } from '../../author/ClassBoard/Transformer'
import RubricGrading from './RubricGrading'
import {
  StyledCardTwo,
  StyledDivSec,
  ScoreInputWrapper,
  ScoreInput,
  TextPara,
  GradingPolicyWrapper,
  GradingPolicy,
  LeaveDiv,
  TitleDiv,
  FeedbackInput,
  FeedbackDisplay,
  UserAvatar,
  FeedbaclInputWrapper,
  FeedbackInputInnerWrapper,
  FeedbackInputInnerWrapper2,
  CustomCheckBox,
} from '../styled/FeedbackRightStyledComponents'
import {
  buildCriteriaRatingMap,
  buildRubricFeedback,
} from '../../author/GradingRubric/Components/common/helper'

const adaptiveRound = (x) =>
  x && x.endsWith ? (x.endsWith('.') ? x : round(x, 2)) : round(x, 2)

function ScoreInputFocusEffectComponent({
  scoreInputRef,
  responseLoading,
  feedbackInputHasFocus,
}) {
  useEffect(() => {
    if (scoreInputRef.current && !responseLoading && !feedbackInputHasFocus) {
      scoreInputRef.current.focus()
    }
  }, [responseLoading])

  return null
}

const isInvalidScore = (score) => (!score || isNaN(score)) && score != 0

const InputType = {
  InputScore: 'inputScore',
  RubricsScore: 'rubricsScore',
}

class FeedbackRight extends Component {
  constructor(props) {
    super(props)

    let { maxScore } = props?.widget?.activity || {}
    let { score } = props?.widget?.activity || {}
    if (!maxScore) {
      maxScore = props?.widget?.validation?.validResponse?.score || 0
    }

    if (props?.isQuestionView && isEmpty(props?.widget?.activity)) {
      score = 0
    }

    this.state = {
      score,
      maxScore,
      showFeedbackSaveBtn: false,
      feedbackInputHasFocus: false,
      clearRubricFeedback: false,
      isRubricDisabled: false,
      isScoreInputDisabled: false,
      showWarningToClear: false,
      isAIEvaluated: false,
      isApprovedByTeacher: false,
      editedRubricResponse: {},
    }

    this.scoreInput = React.createRef()
    this.feedbackInputRef = React.createRef()
    this.scoreRegex = new RegExp(/^[0-9]*(\.){0,1}([0-9]+)?$/)
  }

  static contextType = AnswerContext

  componentDidUpdate(prevProps) {
    const {
      studentQuestionResponseUpdatedAt: prevStudentQuestionResponseUpdatedAt,
      isClassResponseLoading: prevIsClassResponseLoading,
      classQuestionResponseData: prevClassQuestionResponseData,
    } = prevProps
    const {
      studentQuestionResponseUpdatedAt,
      isClassResponseLoading,
      classQuestionResponseData,
      widget,
    } = this.props

    if (prevProps?.widget?.activity && !widget?.activity) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ score: 0 })
    }
    if (prevProps?.widget?.activity?.score !== widget?.activity?.score) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        changed: false,
      })
    }

    /**
     * Express grader, Question Centric, Student Centric
     * Enable inputs after score gets updated
     */
    if (
      prevStudentQuestionResponseUpdatedAt !==
        studentQuestionResponseUpdatedAt ||
      prevIsClassResponseLoading !== isClassResponseLoading ||
      !isEqual(prevClassQuestionResponseData, classQuestionResponseData)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isRubricDisabled: false,
        isScoreInputDisabled: false,
      })
    }
  }

  componentDidMount() {
    const { expressGrader } = this.context || {}
    if (expressGrader === true) {
      this.scoreInput?.current?.focus()
    }
  }

  static getDerivedStateFromProps(
    { widget: { activity, validation }, isQuestionView },
    preState
  ) {
    let newState = {}
    const { submitted, feedback, maxScore, changed, qActId: _qActId } =
      preState || {}
    if (submitted) {
      newState = {
        submitted: false,
        changed: false,
      }
    }

    if (activity && isUndefined(changed)) {
      let { score: _score } = activity
      const { scoreByAI, graded } = activity
      const { qActId, _id } = activity
      let { maxScore: _maxScore } = activity
      let _feedback = get(activity, 'feedback.text', '')
      const feedbackByAI = get(activity, 'feedbackByAI.text', '')
      const isTeacherEvaluationPresent = _score || !isEmpty(_feedback)
      const isAIEvaluationPresent = scoreByAI || !isEmpty(feedbackByAI)
      const showAIEvaluatedResult = [
        !graded,
        !isTeacherEvaluationPresent,
        isAIEvaluationPresent,
      ].every((o) => !!o)
      if (showAIEvaluatedResult) {
        _score = scoreByAI
      }
      if (showAIEvaluatedResult) {
        _feedback = feedbackByAI
      }
      newState = {
        ...newState,
        qActId: qActId || _id,
        isAIEvaluated: showAIEvaluatedResult,
      }

      if (isQuestionView && isEmpty(activity)) {
        _score = 0
      }

      if (!isInvalidScore(_score) || _qActId !== (qActId || _id)) {
        newState = { ...newState, score: _score }
      }

      if (!_maxScore) {
        _maxScore = validation?.validResponse?.score || 0
      }

      if (_maxScore !== maxScore) {
        newState = { ...newState, maxScore: _maxScore }
      }

      if (_feedback !== feedback) {
        newState = { ...newState, feedback: _feedback }
      }

      return newState
    }

    return newState
  }

  getTestActivityId() {
    const { studentId, classBoardData } = this.props
    const testActivity =
      classBoardData?.testActivities?.find((x) => x?.userId === studentId) || {}
    return testActivity._id
  }

  onScoreSubmit(rubricResponse) {
    const { score, maxScore, isAIEvaluated } = this.state
    const { currentScreen } = this.context
    const {
      user,
      updateQuestionActivityScore,
      widget: { id, activity = {} },
      studentId,
      itemId,
      allAnswers = {},
      isExpressGrader,
      rubricDetails,
      match,
    } = this.props

    if (isInvalidScore(score)) {
      notification({ type: 'warn', messageKey: 'scoreShouldNumber' })
      return
    }
    const _score = toNumber(score)
    if (_score > maxScore) {
      notification({ type: 'warn', messageKey: 'scoreShouldLess' })
      return
    }

    const {
      testActivityId,
      groupId = match?.params?.classId,
      testItemId,
    } = activity

    if (!isEmpty(rubricResponse)) {
      const criteriaRatingMap = buildCriteriaRatingMap(rubricDetails.criteria)

      for (const criteriaId of Object.keys(rubricResponse.rubricFeedback)) {
        const ratingId = rubricResponse.rubricFeedback[criteriaId]?.ratingId
        if (!ratingId) {
          delete rubricResponse.rubricFeedback[criteriaId]
          continue
        }
        rubricResponse.rubricFeedback[criteriaId] = buildRubricFeedback(
          { [criteriaId]: ratingId },
          criteriaRatingMap
        )[criteriaId]
      }
    }

    if (isAIEvaluated && !isEmpty(rubricResponse)) {
      this.setState({ editedRubricResponse: rubricResponse })
    }

    const cannotSubmitScore = [!id, !user, !user.user].some((o) => !!o)
    if (cannotSubmitScore) {
      return
    }

    const payload = {
      score: rubricResponse || { score: _score },
      testActivityId: testActivityId || this.getTestActivityId(),
      questionId: id,
      itemId: testItemId || itemId,
      groupId,
      studentId,
    }

    if (currentScreen === 'live_class_board') {
      payload.shouldReceiveStudentResponse = true
    }

    if (isExpressGrader) {
      payload.fromExpressGrader = true
    }

    if (payload.testActivityId && payload.itemId) {
      if (allAnswers[id]) {
        // adding user responses only when not empty
        if (hasValidAnswers(activity?.qType, allAnswers[id])) {
          payload.userResponse = { [id]: allAnswers[id] }
        } else {
          Sentry.configureScope((scope) => {
            scope.setExtra('qType', activity?.qType)
            scope.setExtra('userResponse', allAnswers[id])
            Sentry.captureMessage('empty response update event', 'info')
          })
        }
      }
      updateQuestionActivityScore(payload)
    }
  }

  onFeedbackSubmit() {
    const { feedback, isAIEvaluated } = this.state
    const {
      user,
      studentId,
      loadFeedbackResponses,
      widget: { id, activity = {} },
      match,
      userThumbnail,
      itemId,
      userFullName,
      isQuestionView,
    } = this.props
    const {
      testActivityId,
      groupId = match?.params?.classId,
      testItemId,
    } = activity

    if (!id || !user || !user.user) {
      return
    }
    loadFeedbackResponses({
      body: {
        feedback: {
          teacherId: user.user._id,
          teacherName: userFullName,
          text: feedback,
          ...(userThumbnail ? { thumbnail: userThumbnail } : {}),
        },
        groupId,
      },
      studentId,
      testActivityId: testActivityId || this.getTestActivityId(),
      questionId: id,
      itemId: testItemId || itemId,
      isQuestionView,
    })
    if (isAIEvaluated) {
      this.setState({ isAIEvaluated: false })
    }
  }

  preCheckSubmit = () => {
    const { changed, showFeedbackSaveBtn } = this.state
    const feedbackHasChanged = changed || showFeedbackSaveBtn

    if (feedbackHasChanged) {
      this.onFeedbackSubmit()
    }
    this.setState({ showFeedbackSaveBtn: false })
  }

  allowToSubmitScore = (eventType) => {
    const { changed } = this.state
    /**
     * in case of student did not visit the question, allow teacher trying to grade first time
     * @see EV-25489
     */
    if (eventType !== 'blur') {
      return false
    }
    const { isQuestionView, widget: { activity = {} } = {} } = this.props
    if (isQuestionView) {
      return isEmpty(activity)
    }
    return activity.isDummy && changed
  }

  submitScore = (e) => {
    const { changed, isAIEvaluated } = this.state
    const allowSubmitScore = this.allowToSubmitScore(e?.type)
    if (changed || allowSubmitScore) {
      if (isAIEvaluated) {
        this.preCheckSubmit()
      }
      this.onScoreSubmit()
    }
    this.setState({ showWarningToClear: false })
  }

  onChangeScore = (showGradingRubricButton) => (value, inputType) => {
    const isValid = this.scoreRegex.test(value)
    if ((isValid && !isNaN(value)) || value === '.') {
      this.setState({ score: value, changed: true })
    }
    // Clearing rubric feedback when changing score from input-box/rubrics
    if (showGradingRubricButton) {
      switch (inputType) {
        case InputType.InputScore:
          this.setState((prevState) => ({
            ...prevState,
            clearRubricFeedback: true,
            isRubricDisabled: true,
            showWarningToClear: false,
          }))
          break
        case InputType.RubricsScore:
          this.setState((prevState) => ({
            ...prevState,
            clearRubricFeedback: false,
            isScoreInputDisabled: true,
          }))
          break
        default:
          break
      }
    }
  }

  onChangeFeedback = (e) => {
    this.setState({
      changed: true,
      feedback: e.target.value,
      showFeedbackSaveBtn: !!e.target.value,
    })
  }

  enableScoreInput = () => {
    this.setState({ isScoreInputDisabled: false })
  }

  onKeyDownFeedback = ({ keyCode }) => {
    /**
     * arrow keys or escape key
     */
    const { expressGrader, studentResponseLoading } = this.context || {}
    if (expressGrader && studentResponseLoading) {
      return
    }
    if ((keyCode >= 37 && keyCode <= 40) || keyCode === 27) {
      this.preCheckSubmit()
      this.submitScore()
    }
  }

  focusScoreInput() {
    const { match } = this.props
    const { path } = match
    const { current } = this.scoreInput
    if (path.search('expressgrader') !== -1 && current) {
      current.focus()
    }
  }

  handleScoreInputFocus = () => {
    this.setState({ showWarningToClear: true })
  }

  handleRubricResponse = (res, isSubmit) => {
    const { isAIEvaluated } = this.state
    this.setState({ score: res.score, changed: true }, () => {
      if (isSubmit) {
        if (isAIEvaluated) {
          this.preCheckSubmit()
        }

        this.onScoreSubmit(res)
      }
    })
  }

  focusFeedbackInput = () => this.setState({ feedbackInputHasFocus: true })

  blurFeedbackInput = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        feedbackInputHasFocus: false,
        isShowFeedbackInput: false,
      })
    }
  }

  showFeedbackInput = () => {
    this.setState(
      { isShowFeedbackInput: true, feedbackInputHasFocus: true },
      () => {
        if (this.feedbackInputRef.current) {
          this.feedbackInputRef.current.focus()
        }
      }
    )
  }

  handleFeedbackApproval = (e) =>
    this.setState({
      isApprovedByTeacher: e.target.checked,
      showFeedbackSaveBtn: e.target.checked,
      changed: true,
    })

  getRubricResponse = () => {
    const { editedRubricResponse, clearRubricFeedback } = this.state
    const {
      widget: { activity },
      rubricDetails,
    } = this.props
    const { rubricFeedbackByAI, scoreByAI } = activity

    if (clearRubricFeedback) {
      return
    }
    let rubricResponse
    if (!isEmpty(editedRubricResponse)) {
      rubricResponse = editedRubricResponse
    } else if (!isEmpty(rubricFeedbackByAI)) {
      rubricResponse = {
        score: scoreByAI,
        rubricFeedback: buildRubricFeedback(
          rubricFeedbackByAI,
          buildCriteriaRatingMap(rubricDetails.criteria)
        ),
        rubricId: rubricDetails?._id,
      }
    }
    return rubricResponse
  }

  handleSave = () => {
    const { isAIEvaluated } = this.state
    this.preCheckSubmit()
    if (isAIEvaluated) {
      const rubricResponse = this.getRubricResponse()
      this.onScoreSubmit(rubricResponse)
    }
  }

  render() {
    const { studentResponseLoading, expressGrader } = this.context
    const {
      studentName,
      widget: { activity },
      isPresentationMode,
      color,
      icon,
      showCollapseBtn,
      rubricDetails,
      user,
      disabled,
      isPracticeQuestion,
      isAbsolutePos,
      hintsUsed,
    } = this.props
    const {
      score,
      maxScore,
      feedback,
      changed,
      showFeedbackSaveBtn,
      feedbackInputHasFocus,
      isShowFeedbackInput,
      clearRubricFeedback,
      isRubricDisabled,
      isScoreInputDisabled,
      showWarningToClear,
      isAIEvaluated,
      isApprovedByTeacher,
    } = this.state
    let rubricMaxScore = 0
    if (rubricDetails?._id)
      rubricMaxScore = sumBy(
        rubricDetails.criteria,
        (c) => maxBy(c.ratings, 'points').points
      )
    let { rubricFeedback } = activity || {}
    const { rubricFeedbackByAI, isGradedExternally, aiEvaluationStatus } =
      activity || {}
    if (isAIEvaluated && rubricDetails?._id) {
      rubricFeedback = buildRubricFeedback(
        rubricFeedbackByAI,
        buildCriteriaRatingMap(rubricDetails.criteria)
      )
    }
    const isStudentName = studentName !== undefined && studentName.length !== 0
    let title
    const showGradingRubricButton =
      user.user?.features?.gradingrubrics && !!rubricDetails?._id

    if (isStudentName) {
      title = (
        <TitleDiv data-cy="studentName">
          {isPresentationMode ? (
            <i
              className={`fa fa-${icon}`}
              style={{ color, fontSize: '32px' }}
            />
          ) : (
            <UserAvatar>{getAvatarName(studentName)}</UserAvatar>
          )}
          &nbsp;
          {studentName}
        </TitleDiv>
      )
    } else {
      title = null
    }

    let _score = adaptiveRound(score || 0)
    if (
      (activity &&
        activity.graded === false &&
        (activity.score === 0 || isUndefined(activity.score)) &&
        !score &&
        !changed) ||
      (activity?.isDummy && expressGrader && !changed)
    ) {
      _score = ''
      if (isAIEvaluated && activity?.scoreByAI === 0) {
        _score = 0
      }
    }
    if (activity && activity.skipped && !changed) {
      _score = '-'
    }

    const _maxScore =
      showGradingRubricButton && rubricMaxScore ? rubricMaxScore : maxScore

    const isError = _maxScore < score
    // TODO: uncomment when practice question scoring is implemented (EV-12869)
    // if (isPracticeQuestion) {
    //   _maxScore = "";
    // }

    const showHintUsed = !isUndefined(hintsUsed)
    const isLikertQuestion = activity.qType === LIKERT_SCALE
    const _isScoreInputDisabled = [
      isPresentationMode,
      isPracticeQuestion,
      isScoreInputDisabled,
    ].some((o) => !!o)

    const previewFeedbackCheckboxHeight = isAIEvaluated ? 35 : 4

    return (
      <StyledCardTwo
        data-cy="feedbackContainer"
        bordered={isStudentName}
        disabled={disabled}
        showCollapseBtn={showCollapseBtn}
        title={title}
      >
        {expressGrader && (
          <ScoreInputFocusEffectComponent
            scoreInputRef={this.scoreInput}
            feedbackInputHasFocus={feedbackInputHasFocus}
            responseLoading={studentResponseLoading}
          />
        )}
        <EduIf condition={!isLikertQuestion}>
          <EduIf condition={!isPracticeQuestion}>
            <EduThen>
              <>
                <StyledDivSec>
                  <ScoreInputWrapper>
                    <ScoreInput
                      data-cy="scoreInput"
                      onChange={(e) =>
                        this.onChangeScore(showGradingRubricButton)(
                          e.target.value,
                          InputType.InputScore
                        )
                      }
                      onFocus={this.handleScoreInputFocus}
                      onBlur={this.submitScore}
                      value={_score}
                      disabled={_isScoreInputDisabled}
                      ref={this.scoreInput}
                      onKeyDown={this.onKeyDownFeedback}
                      tabIndex={0}
                    />
                    <TextPara>{_maxScore}</TextPara>
                  </ScoreInputWrapper>
                </StyledDivSec>
                <GradingPolicyWrapper>
                  GRADING POLICY &nbsp;
                  <GradingPolicy data-cy="gradingPolicyType">
                    {activity.scoringType}
                  </GradingPolicy>
                </GradingPolicyWrapper>
              </>
            </EduThen>
            <EduElse>
              <UnScored
                data-cy="unscoredInput"
                text="Zero Point"
                height="50px"
              />
            </EduElse>
          </EduIf>
        </EduIf>

        <EduIf condition={showHintUsed && !isLikertQuestion}>
          <GradingPolicyWrapper>
            HINTS USED &nbsp;
            <GradingPolicy data-cy="hintsUsed">
              {hintsUsed ? 'Yes' : 'No'}
            </GradingPolicy>
          </GradingPolicyWrapper>
        </EduIf>

        {showGradingRubricButton && (
          <RubricGrading
            rubricData={rubricDetails}
            maxScore={rubricMaxScore}
            rubricFeedback={rubricFeedback}
            currentScore={activity?.score}
            onRubricResponse={this.handleRubricResponse}
            isRubricDisabled={isRubricDisabled}
            onChangeScore={this.onChangeScore(showGradingRubricButton)}
            clearRubricFeedback={clearRubricFeedback}
            InputType={InputType}
            inputScore={_score}
            showWarningToClear={showWarningToClear}
            enableScoreInput={this.enableScoreInput}
            isGradedExternally={isGradedExternally}
            aiEvaluationStatus={aiEvaluationStatus}
          />
        )}
        {!isError && (
          <FeedbaclInputWrapper>
            <FeedbackInputInnerWrapper isAbsolutePos={isAbsolutePos}>
              <LeaveDiv>
                <span>
                  {isError ? 'Score is too large' : 'Student Feedback!'}
                </span>
                {showFeedbackSaveBtn && (
                  <EduButton height="32px" onClick={this.handleSave}>
                    Save
                  </EduButton>
                )}
              </LeaveDiv>
              {!isShowFeedbackInput && (
                <FeedbackDisplay onClick={this.showFeedbackInput}>
                  {feedback}
                </FeedbackDisplay>
              )}
              <FeedbackInputInnerWrapper2
                onBlur={this.blurFeedbackInput}
                tabIndex={0}
              >
                <FeedbackInput
                  tabIndex={0}
                  ref={this.feedbackInputRef}
                  data-cy="feedBackInput"
                  onChange={this.onChangeFeedback}
                  value={feedback}
                  onFocus={this.focusFeedbackInput}
                  disabled={!activity || isPresentationMode}
                  onKeyDown={this.onKeyDownFeedback}
                  isShowFeedbackInput={isShowFeedbackInput}
                  paddingBottom={previewFeedbackCheckboxHeight}
                  autoSize={{ minRows: 4, maxRows: 7 }}
                />
                <EduIf
                  condition={[isShowFeedbackInput, isAIEvaluated].every(
                    (o) => !!o
                  )}
                >
                  <CustomCheckBox
                    onChange={this.handleFeedbackApproval}
                    checked={isApprovedByTeacher}
                    height={previewFeedbackCheckboxHeight}
                  >
                    FEEDBACK IS REVIEWED
                  </CustomCheckBox>
                </EduIf>
              </FeedbackInputInnerWrapper2>
            </FeedbackInputInnerWrapper>
          </FeedbaclInputWrapper>
        )}
      </StyledCardTwo>
    )
  }
}

FeedbackRight.propTypes = {
  widget: PropTypes.shape({
    evaluation: PropTypes.object,
  }).isRequired,
  user: PropTypes.object.isRequired,
  studentName: PropTypes.string,
  loadFeedbackResponses: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isPresentationMode: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
}

FeedbackRight.defaultProps = {
  studentName: '',
  isPresentationMode: false,
  color: '',
  icon: '',
}

const enhance = compose(
  React.memo,
  withRouter,
  withWindowSizes,
  withNamespaces('header'),
  connect(
    (state) => ({
      user: getUserSelector(state),
      waitingResponse: getStatus(state),
      errorMessage: getErrorResponse(state),
      classBoardData: state.author_classboard_testActivity?.data,
      allAnswers: state?.answers,
      userThumbnail: getUserThumbnail(state),
      userFullName: getUserFullNameSelector(state),
      isClassResponseLoading: state?.studentResponse?.loading,
      studentQuestionResponseUpdatedAt:
        state?.studentQuestionResponse?.data?.updatedAt,
      classQuestionResponseData: state.classQuestionResponse.data,
    }),
    {
      loadFeedbackResponses: receiveFeedbackResponseAction,
      updateQuestionActivityScore: updateStudentQuestionActivityScoreAction,
      setTeacherEditedScore: setTeacherEditedScoreAction,
    }
  )
)
export default enhance(FeedbackRight)
