import { createSlice } from 'redux-starter-kit'

import { RESET_ALL_REPORTS } from '../../../../common/reportsRedux'
import { staticDropDownData } from '../utils'

const reduxNamespaceKey = 'adaptiveReports'

const initialState = {
  firstLoad: true,
  loadingFilterData: false,
  loadingStudentList: false,
  filterTabKey: staticDropDownData.filterSections.TEST_FILTERS.key,
  filters: { ...staticDropDownData.initialFilters },
  filtersData: {},
  prevFiltersData: {},
  filterTagsData: {},
  student: { key: '', title: '' },
  settings: {
    requestFilters: {},
    selectedStudent: { key: '', title: '' },
    selectedFilterTagsData: {},
  },
  studentListQuery: null,
  studentList: [],
  error: false,
}

const slice = createSlice({
  slice: reduxNamespaceKey,
  initialState: { ...initialState },
  reducers: {
    fetchFilterData: (state) => {
      state.loading = true
    },
    fetchFilterDataSuccess: (state, { payload }) => {
      state.loading = false
      state.filtersData = payload.filtersData
      state.summaryDataRequestError = false
    },
    fetchFilterDataError: (state, { payload }) => {
      state.loading = false
      state.filtersData = {}
      state.summaryDataRequestError = payload.error
    },
    fetchStudentList: (state) => {
      state.loadingStudentList = true
    },
    fetchStudentListSuccess: (state, { payload }) => {
      state.loadingStudentList = false
      state.studentListQuery = payload.studentListQuery
      state.studentList = payload.studentList
      state.error = false
    },
    fetchStudentListError: (state, { payload }) => {
      state.loadingStudentList = false
      state.error = payload.error
    },
    setFirstLoad: (state, { payload }) => {
      state.firstLoad = payload
    },
    setFilterTabKey: (state, { payload }) => {
      state.filterTabKey = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    setFilterTagsData: (state, { payload }) => {
      state.filterTagsData = payload
    },
    setSelectedFilterTagsData: (state, { payload }) => {
      state.settings.selectedFilterTagsData = payload
    },
    setSettings: (state, { payload }) => {
      state.settings = payload
    },
    setStudent: (state, { payload }) => {
      state.student = payload
    },
    setPrevFilterData: (state, { payload }) => {
      state.prevFiltersData = payload
    },
  },
  extraReducers: {
    [RESET_ALL_REPORTS]: () => ({ ...initialState }),
  },
})

export const { actions, reducer } = slice
export { reduxNamespaceKey }
