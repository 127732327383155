import React, { useState } from 'react'
import { Popover, Select } from 'antd'
import { sessionFilters } from '@edulastic/constants/const/common'
import { connect } from 'react-redux'
import { EduButton } from '@edulastic/common'
import { segmentApi } from '@edulastic/api'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import QueryString from 'qs'
import {
  IconArrowCircleRight,
  IconInfoCircle,
  IconSearch,
} from '@edulastic/icons'
import { lightGrey2 } from '@edulastic/colors'
import AssignmentsSearch from '../../../../Assignments/components/AssignmentHeaderFilter/AssignmentsSearch'
import {
  getCurrentTerm,
  getUserId,
  getUserOrgId,
} from '../../../../src/selectors/user'
import {
  getFilterFromSession,
  setFilterInSession,
} from '../../../../../common/utils/helpers'
import {
  SearchBoxContainer,
  SearchDropdown,
  StyledInput,
  StyledToolTipContainer,
} from './styled'
import { searchTypes } from './constants'

const TooltipNode = ({ type }) => {
  return (
    <StyledToolTipContainer>
      <IconInfoCircle width="16px" height="16px" color={lightGrey2} />
      <div>Type and press enter to search {type}.</div>
    </StyledToolTipContainer>
  )
}

const SearchBox = ({ districtId, userId, termId, history }) => {
  const [searchType, setSearchType] = useState(() => {
    const storedFilterValue = getFilterFromSession({
      key: sessionFilters.DASHBOARD_SEARCH,
      districtId,
      userId,
    })
    if (typeof storedFilterValue !== 'string') return searchTypes.TESTS
    return storedFilterValue
  })

  const [searchQuery, setSearchQuery] = useState('')

  const searchTypeChangeCallback = (newSearchType) => {
    if (newSearchType === searchType) return
    segmentApi.genericEventTrack('Dashboard:Search:Switch', {
      to: newSearchType,
      from: searchType,
    })
    setSearchType(newSearchType)
    setSearchQuery('')
    setFilterInSession({
      key: sessionFilters.DASHBOARD_SEARCH,
      districtId,
      userId,
      filter: newSearchType,
    })
  }

  const searchSelect = (filters) => {
    switch (searchType) {
      case searchTypes.TESTS:
        history.push(
          `/author/tests?${QueryString.stringify({
            filter: 'ENTIRE_LIBRARY',
            searchString: [searchQuery],
          })}`
        )
        break
      case searchTypes.ITEMS:
        {
          const existingFilter = getFilterFromSession({
            key: 'filters[itemList]',
            districtId,
            userId,
          })
          existingFilter.filter = 'ENTIRE_LIBRARY'
          existingFilter.searchString = [searchQuery]
          setFilterInSession({
            key: 'filters[itemList]',
            districtId,
            userId,
            filter: existingFilter,
          })
          history.push('/author/items')
        }
        break
      case searchTypes.ASSIGNMENTS:
        {
          const existingAssignmentsFilter = {
            termId,
            testId: filters,
            showFilter: true,
          }
          setFilterInSession({
            key: 'assignments_filter',
            districtId,
            userId,
            filter: existingAssignmentsFilter,
          })
          history.push('/author/assignments')
        }
        break
      default:
        console.error('This is not expected')
        break
    }
  }

  return (
    <SearchBoxContainer
      flexDirection="row"
      flexWrap="nowrap"
      data-joyride-id="contentSearch"
    >
      <SearchDropdown
        value={searchType}
        dropdownStyle={{ zIndex: '999' }}
        onChange={searchTypeChangeCallback}
        data-cy="headerSearchType"
        aria-label="Select Search Type"
      >
        {Object.values(searchTypes).map((searchTypeValue) => (
          <Select.Option value={searchTypeValue} key={searchTypeValue}>
            {searchTypeValue}
          </Select.Option>
        ))}
      </SearchDropdown>
      {searchType === searchTypes.TESTS && (
        <Popover trigger="hover" content={<TooltipNode type="tests" />}>
          <StyledInput
            placeholder={'Search "Tests"'}
            aria-label="Search Tests"
            value={searchQuery}
            onChange={({ target }) => setSearchQuery(target.value)}
            onPressEnter={searchSelect}
            prefix={<IconSearch color={lightGrey2} />}
            suffix={
              <EduButton
                isGhost
                IconBtn
                noBorder
                style={{
                  background: 'none',
                }}
                type="secondary"
                disabled={!searchQuery}
                onClick={searchSelect}
                width="25px"
                data-cy="headerNavigationButton"
                aria-label="Search tests"
              >
                <IconArrowCircleRight />
              </EduButton>
            }
            data-cy="headerSearchBox"
          />
        </Popover>
      )}
      {searchType === searchTypes.ITEMS && (
        <Popover trigger="hover" content={<TooltipNode type="items" />}>
          <StyledInput
            placeholder={'Search "Items"'}
            aria-label="Search Items"
            value={searchQuery}
            onChange={({ target }) => setSearchQuery(target.value)}
            onPressEnter={searchSelect}
            prefix={<IconSearch color={lightGrey2} />}
            suffix={
              <EduButton
                isGhost
                IconBtn
                noBorder
                style={{
                  background: 'none',
                }}
                type="secondary"
                width="25px"
                data-cy="headerNavigationButton"
                disabled={!searchQuery}
                onClick={searchSelect}
                aria-label="Search Items"
              >
                <IconArrowCircleRight />
              </EduButton>
            }
            data-cy="headerSearchBox"
          />
        </Popover>
      )}
      {searchType === searchTypes.ASSIGNMENTS && (
        <AssignmentsSearch
          onSetFilter={searchSelect}
          populateSearchResult={false}
          searchString={searchQuery}
          setSearchString={(value) => setSearchQuery(value)}
        />
      )}
    </SearchBoxContainer>
  )
}

const enhance = compose(
  withRouter,
  connect((state) => ({
    districtId: getUserOrgId(state),
    userId: getUserId(state),
    termId: getCurrentTerm(state),
  }))
)
export default enhance(SearchBox)
