import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { WithMathFormula } from '@edulastic/common'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'

const MathSpanWrapper = ({ latex, fontSize, fontWeight }) => {
  const template = `<span><span class="input__math" data-latex="${latex}"></span></span>`
  return (
    <MathSpan
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(template) }}
      fontSize={fontSize}
      fontWeight={fontWeight}
    />
  )
}

MathSpanWrapper.propTypes = {
  latex: PropTypes.string.isRequired,
}

export default MathSpanWrapper

const MathSpan = WithMathFormula(styled.span`
  position: relative;

  .katex {
    font-weight: ${({ fontWeight }) => fontWeight};
  }
`)
