import selectsData from '../TestPage/components/common/selectsData'

const vqTabs = {
  COMMUNITY: 'community',
  MY_CONTENT: 'myContent',
  YOUTUBE: 'youtube',
}
export const vqConst = {
  reduxNamespaceKey: 'videoQuizReducer',
  defaultTab: 'community',
  ytLinkPrefix: 'https://www.youtube.com/watch?v=',
  resultLimit: 20,
  vqTabs,
  sort: {
    [vqTabs.COMMUNITY]: {
      sortBy: 'popularity',
      sortDir: 'desc',
    },
    [vqTabs.MY_CONTENT]: {
      sortBy: 'recency',
      sortDir: 'desc',
    },
  },
}

const { allGrades, allSubjects, allStatus } = selectsData

export const filterDetails = {
  grades: {
    filterHeader: 'Grades',
    filterKey: 'grades',
    placeholder: 'All Grades',
    options: allGrades,
    mode: 'multiple',
    ariaLabel: 'Select Grades',
  },
  subjects: {
    filterHeader: 'Subjects',
    filterKey: 'subject',
    placeholder: 'All Status',
    options: allSubjects,
    mode: 'multiple',
    ariaLabel: 'Select Status',
  },
  status: {
    filterHeader: 'All Status',
    filterKey: 'status',
    placeholder: 'All Status',
    options: allStatus,
    mode: 'single',
    ariaLabel: 'Select Status',
  },
}

export const VQ_UPGRADE_INCLUSIONS = [
  { message: 'Unlimited Video Quizzes' },
  { message: 'Generate Questions using AI' },
  {
    message: 'YouTube for Education',
    tooltipContent:
      'Enables ad-free viewing and limits recommendations to ensure students focus solely on learning.',
  },
]

export const bannerConfig = (count) => ({
  free: {
    title: `Our Free Plan Includes ${count} Free Video Quizzes`,
  },
  upgrade: {
    title: 'Upgrade to Video Quiz Add on',
    description:
      'Get Unlimited Video Quizzes, Generate Questions using AI, Enables Youtube for Education',
    buttonText: 'Buy Add On',
  },
})

export const vqBannerCloseKey = 'vqBannerClose'
